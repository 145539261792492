import React, { useMemo } from 'react';
import StarRatings from 'react-star-ratings';
import MainLayout from '../../../_layout/MainLayout';
import useJobDetails from './useJobDetails';
import { jobApplyStatus } from '../../../_helpers/types';
import ResumeFineTuneSelectDropdown from '../../../_component/ResumeFineTuneSelectDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Spin, Tooltip } from 'antd';

function JobDetails(props) {
  const {
    jobForm,
    userResume,
    selectedResume,
    fineTuneResumeLoading,
    mockInterviewLoading,
    selectedResumeDetails,
    onResumeSelectClick,
    onJobUpdate,
    onFineTunedResumeClick,
    onGenerateMockInterviewClick,
    handleOpenLink,
  } = useJobDetails(props);
  const getIndex = useMemo(() => {
    let id = null;
    Object.keys(jobApplyStatus).forEach((val, index) => {
      if (jobForm?.status === jobApplyStatus[val]) id = index;
    });
    return id;
  }, [jobForm]);

  return (
    <MainLayout activeMenuItem="home">
      <div className="p-4 sticky">
        <h2 className="text-2xl font-medium p-1">Job Preview</h2>
        <hr />
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-dark text-lg font-medium">{jobForm?.title}</h2>
            <p className="text-[#2F6D6A] font-medium">
              {jobForm?.company}{' '}
              <button className="ml-2" onClick={() => handleOpenLink(jobForm?.bookmark_url)}>
                {' '}
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="h-4 text-[#2F6D6A]" />
              </button>
            </p>
            <p>{jobForm?.location}</p>
          </div>
          <div>
            <div className="m-2 text-end">
              <StarRatings
                rating={jobForm?.interested}
                starRatedColor="#FFCC2A"
                changeRating={(e) => onJobUpdate(e, 'interested')}
                numberOfStars={5}
                name="interested"
                starDimension="20"
                starHoverColor="#FCDDB0"
              />
            </div>
            <div className="my-2 flex flex-col items-end gap-1">
              {jobForm?.fine_tuned_resume ? (
                <>
                  <ResumeFineTuneSelectDropdown
                    userResume={userResume}
                    onResumeSelectClick={onResumeSelectClick}
                    selectedResume={selectedResume}
                  />

                  <button
                    onClick={onFineTunedResumeClick}
                    className="font-medium py-1 px-2 bg-[#2F6D6A] text-white rounded-full"
                  >
                    View Fine Tuned Resume
                  </button>

                  <Tooltip title="Please select the resume to take the Mock Interview" disabled={selectedResumeDetails}>
                    <button
                      className="font-medium py-1 px-2 bg-[#2F6D6A] text-white rounded-full disabled:bg-gray-600"
                      onClick={onGenerateMockInterviewClick}
                      disabled={mockInterviewLoading || !selectedResumeDetails} // Disable if loading or no resume selected
                    >
                      {mockInterviewLoading ? <Spin /> : 'Take Mock Interview'}
                    </button>
                  </Tooltip>
                </>
              ) : (
                <>
                  <ResumeFineTuneSelectDropdown
                    userResume={userResume}
                    onResumeSelectClick={onResumeSelectClick}
                    selectedResume={selectedResume}
                    fineTuneResumeLoading={fineTuneResumeLoading}
                    title={'Fine Tune Resume'}
                  />
                  <Tooltip title="Please select the resume to take the Mock Interview" disabled={selectedResumeDetails}>
                    <button
                      className="font-medium py-1 px-2 bg-[#2F6D6A] text-white rounded-full disabled:bg-gray-600"
                      onClick={onGenerateMockInterviewClick}
                      disabled={mockInterviewLoading || !selectedResumeDetails} // Disable if loading or no resume selected
                    >
                      {mockInterviewLoading ? <Spin /> : 'Take Mock Interview'}
                    </button>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className="flex my-4 justify-evenly flex-wrap max-[1024px]:justify-between gap-y-2 w-full">
          {Object.keys(jobApplyStatus).map((item, index) => (
            <button
              className={`btn rounded-2xl mr-1 ${getIndex >= index ? `bg-[#2F6D6A] text-white` : 'bg-[#F2F6F7]'} p-3 `}
              onClick={() => onJobUpdate(jobApplyStatus[item], 'status')}
            >
              <span className="px-4 ">{jobApplyStatus[item]}</span>
            </button>
          ))}
        </div>

        <div className="mt-4">
          <h2 className="text-lg my-1 font-semibold">Description</h2>
          <div dangerouslySetInnerHTML={{ __html: jobForm?.job_details }} />
        </div>
      </div>
    </MainLayout>
  );
}

export default JobDetails;
