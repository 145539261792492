import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetch_requested_referral_details_action, fetch_user_referral_requests_action } from '../../../_redux/action';
import { AuthContext } from '../../../_hooks/useAuthContext';
import { navigateRoutes } from '../../../_config/route';

export default function useReferralDetails(props) {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [requestedReferralDetails, setRequestedReferralDetails] = useState([]);
  const [acceptReferralModalOpen, setAcceptReferralModalOpen] = useState(false);
  const [fulfillReferralModalOpen, setFulfillReferralModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { requestedReferralDetailsData } = useSelector((state) => state.fetch_requested_referral_details_reducer);

  useEffect(() => {
    if (requestedReferralDetailsData) {
      setRequestedReferralDetails(requestedReferralDetailsData);
    }
  }, [requestedReferralDetailsData]);

  useEffect(() => {
    const callRequestedReferralsApi = async () => {
      if (params?.id) {
        setLoading(true);
        await dispatch(fetch_requested_referral_details_action(params.id));
        setLoading(false);
      }
    };
    callRequestedReferralsApi();
  }, [params]);

  const handleJobViewClick = () => {
    window.open(requestedReferralDetails?.job_id?.bookmark_url, '_blank');
  };

  const toggleAcceptReferralModalOpen = async () => {
    setAcceptReferralModalOpen((v) => !v);
    await dispatch(fetch_requested_referral_details_action(params.id));
  };

  const filterAcceptedReferral = () => {
    if (requestedReferralDetails?.referral_accept && Array.isArray(requestedReferralDetails?.referral_accept)) {
      if (
        requestedReferralDetails?.referral_fulfill &&
        Array.isArray(requestedReferralDetails?.referral_fulfill && requestedReferralDetails.referral_fulfill.length)
      ) {
        return 0;
      }
      return requestedReferralDetails?.referral_accept.filter((referral) => referral.referrer_id === user?.user?._id)
        .length;
    }
    return 0;
  };

  const toggleFulfillReferralModalOpen = async () => {
    setFulfillReferralModalOpen((v) => !v);
    await dispatch(fetch_requested_referral_details_action(params.id));
  };

  const handleCandidateProfileClick = () => {
    navigate(navigateRoutes.candidatePublicProfile.replace(':id', requestedReferralDetails?.user_id?._id));
  };

  return {
    loading,
    user,
    requestedReferralDetails,
    acceptReferralModalOpen,
    fulfillReferralModalOpen,
    handleJobViewClick,
    toggleAcceptReferralModalOpen,
    filterAcceptedReferral,
    toggleFulfillReferralModalOpen,
    handleCandidateProfileClick
  };
}
