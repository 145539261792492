import React from 'react';
import MainLayout from '../../../_layout/MainLayout';
import useReferralRequests from './useReferralRequests';
import { Spin, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faLayerGroup, faLocationDot, faMessage } from '@fortawesome/free-solid-svg-icons';
import AddReferralRequestModal from '../../../_component/AddReferralRequestModal';

const ReferralRequests = (props) => {
  const {
    loading,
    referralRequests,
    requestReferralModalOpen,
    selectedReferral,
    toggleReferralRequestModal,
    onReferralDetailsClick,
    onCurrentTabChange
  } = useReferralRequests(props);

  return (
    <MainLayout activeMenuItem="home">
      <div className="p-4 sticky h-screen">
        <div className="centered justify-between mb-4 window-container">
          <h2 className="window-title">Ask Referral</h2>
          <div className="centered justify-center ">
            <div className="centered">
              <button
                className="btn bg-slate-50 text-gray-900 rounded-xl outline outline-1 hover:bg-slate-200"
                onClick={() => toggleReferralRequestModal(null)}
              >
                Add Referral Request
              </button>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="centered h-[80vh] spinner-box">
            <Spin size="large" />
          </div>
        ) : (
          <div className="flex flex-col gap-4 pb-4 shadow-[5px_#0003]">
            {/* <div className="flex flex-row items-center gap-4">
              <button className="py-2 px-4 border-b-2 border-black font-medium ">All</button>
              <button className="py-2 px-4 font-medium">Pending</button>
              <button className="py-2 px-4 font-medium">Fulfilled</button>
            </div> */}
            <Tabs defaultActiveKey="1" onChange={ onCurrentTabChange}>
              <Tabs.TabPane tab="All" key="all" className="py-2 px-4 border-b-2 font-medium">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4 pb-4 shadow-[5px_#0003] rounded-2xl bg-[#faf9f8] p-4 gap-4">
                  {referralRequests && referralRequests.length > 0 ? (
                    referralRequests.map((referral) => (
                      <div
                        className="p-4 grid grid-cols-[auto_1fr] gap-3 rounded-2xl border items-center bg-white"
                        key={referral?._id}
                      >
                        <div className="flex flex-col">
                          <div className="Job-type-tags flex flex-row gap-2">
                            <span className="p-1.5 rounded-lg bg-[#FFE0A9] text-xs">
                              {referral?.company_id?.company_name}
                            </span>
                          </div>
                          <h3 className="text-2xl font-medium mt-1">
                            {referral?.job_id?.title?.trim().replace(/^\w/, (c) => c.toUpperCase())}
                          </h3>
                          <div className="flex flex-row gap-4 items-center mt-1">
                            <p className="text-xs">
                              <FontAwesomeIcon icon={faLocationDot} className="mr-0.5" />{' '}
                              {referral?.country_id?.display_name || 'N/A'}
                            </p>
                            <p className="text-xs">
                              <FontAwesomeIcon icon={faLayerGroup} className="mr-0.5" />{' '}
                              {referral?.seniority_level?.replace(/^\w/, (c) => c.toUpperCase()) || 'N/A'}
                            </p>
                          </div>
                          <p className="text-xs mt-2">
                            <FontAwesomeIcon icon={faMessage} className="mr-0.3" /> {referral?.request_message}
                          </p>
                        </div>
                        <div className="flex flex-col items-end justify-between gap-2">
                          <p className="bg-[#e5ecec] px-3 text-[#003742] rounded-3xl text-sm">
                            {referral?.status?.replace(/^\w/, (c) => c.toUpperCase())}
                          </p>
                          {/* View Button */}
                          <div className="flex flex-row gap-2">
                            <button
                              onClick={() => onReferralDetailsClick(referral)}
                              className="p-1 btn text-gray-900 hover:bg-slate-200"
                            >
                              <FontAwesomeIcon icon={faEye} className="mr-1" />
                            </button>
                            <button
                              className="p-1 btn text-gray-900 hover:bg-slate-200"
                              onClick={() => toggleReferralRequestModal(referral)}
                            >
                              <FontAwesomeIcon icon={faEdit} className="mr-1" />
                            </button>
                          </div>
                          <span className="text-xs text-gray-500">
                            {referral?.createdAt
                              ? new Date(referral.createdAt).toLocaleDateString('en-US', {
                                  day: '2-digit',
                                  month: 'long',
                                  year: 'numeric',
                                })
                              : 'No date available'}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No Referrals Found</p>
                  )}
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Accepted" key="accepted" className="py-2 px-4 border-b-2 font-medium">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4 pb-4 shadow-[5px_#0003] rounded-2xl bg-[#faf9f8] p-4 gap-4">
                  {referralRequests && referralRequests.length > 0 ? (
                    referralRequests.map((referral) => (
                      <div
                        className="p-4 grid grid-cols-[auto_1fr] gap-3 rounded-2xl border items-center bg-white"
                        key={referral?._id}
                      >
                        <div className="flex flex-col">
                          <div className="Job-type-tags flex flex-row gap-2">
                            <span className="p-1.5 rounded-lg bg-[#FFE0A9] text-xs">
                              {referral?.company_id?.company_name}
                            </span>
                          </div>
                          <h3 className="text-2xl font-medium mt-1">
                            {referral?.job_id?.title?.trim().replace(/^\w/, (c) => c.toUpperCase())}
                          </h3>
                          <div className="flex flex-row gap-4 items-center mt-1">
                            <p className="text-xs">
                              <FontAwesomeIcon icon={faLocationDot} className="mr-0.5" />{' '}
                              {referral?.country_id?.display_name || 'N/A'}
                            </p>
                            <p className="text-xs">
                              <FontAwesomeIcon icon={faLayerGroup} className="mr-0.5" />{' '}
                              {referral?.seniority_level?.replace(/^\w/, (c) => c.toUpperCase()) || 'N/A'}
                            </p>
                          </div>
                          <p className="text-xs mt-2">
                            <FontAwesomeIcon icon={faMessage} className="mr-0.3" /> {referral?.request_message}
                          </p>
                        </div>
                        <div className="flex flex-col items-end justify-between gap-2">
                          <p className="bg-[#e5ecec] px-3 text-[#003742] rounded-3xl text-sm">
                            {referral?.status?.replace(/^\w/, (c) => c.toUpperCase())}
                          </p>
                          {/* View Button */}
                          <div className="flex flex-row gap-2">
                            <button
                              onClick={() => onReferralDetailsClick(referral)}
                              className="p-1 btn text-gray-900 hover:bg-slate-200"
                            >
                              <FontAwesomeIcon icon={faEye} className="mr-1" />
                            </button>
                            <button
                              className="p-1 btn text-gray-900 hover:bg-slate-200"
                              onClick={() => toggleReferralRequestModal(referral)}
                            >
                              <FontAwesomeIcon icon={faEdit} className="mr-1" />
                            </button>
                          </div>
                          <span className="text-xs text-gray-500">
                            {referral?.createdAt
                              ? new Date(referral.createdAt).toLocaleDateString('en-US', {
                                  day: '2-digit',
                                  month: 'long',
                                  year: 'numeric',
                                })
                              : 'No date available'}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No Referrals Found</p>
                  )}
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Fulfilled" key="fulfilled" className="py-2 px-4 border-b-2 font-medium">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4 pb-4 shadow-[5px_#0003] rounded-2xl bg-[#faf9f8] p-4 gap-4">
                  {referralRequests && referralRequests.length > 0 ? (
                    referralRequests.map((referral) => (
                      <div
                        className="p-4 grid grid-cols-[auto_1fr] gap-3 rounded-2xl border items-center bg-white"
                        key={referral?._id}
                      >
                        <div className="flex flex-col">
                          <div className="Job-type-tags flex flex-row gap-2">
                            <span className="p-1.5 rounded-lg bg-[#FFE0A9] text-xs">
                              {referral?.company_id?.company_name}
                            </span>
                          </div>
                          <h3 className="text-2xl font-medium mt-1">
                            {referral?.job_id?.title?.trim().replace(/^\w/, (c) => c.toUpperCase())}
                          </h3>
                          <div className="flex flex-row gap-4 items-center mt-1">
                            <p className="text-xs">
                              <FontAwesomeIcon icon={faLocationDot} className="mr-0.5" />{' '}
                              {referral?.country_id?.display_name || 'N/A'}
                            </p>
                            <p className="text-xs">
                              <FontAwesomeIcon icon={faLayerGroup} className="mr-0.5" />{' '}
                              {referral?.seniority_level?.replace(/^\w/, (c) => c.toUpperCase()) || 'N/A'}
                            </p>
                          </div>
                          <p className="text-xs mt-2">
                            <FontAwesomeIcon icon={faMessage} className="mr-0.3" /> {referral?.request_message}
                          </p>
                        </div>
                        <div className="flex flex-col items-end justify-between gap-2">
                          <p className="bg-[#e5ecec] px-3 text-[#003742] rounded-3xl text-sm">
                            {referral?.status?.replace(/^\w/, (c) => c.toUpperCase())}
                          </p>
                          {/* View Button */}
                          <div className="flex flex-row gap-2">
                            <button
                              onClick={() => onReferralDetailsClick(referral)}
                              className="p-1 btn text-gray-900 hover:bg-slate-200"
                            >
                              <FontAwesomeIcon icon={faEye} className="mr-1" />
                            </button>
                            <button
                              className="p-1 btn text-gray-900 hover:bg-slate-200"
                              onClick={() => toggleReferralRequestModal(referral)}
                            >
                              <FontAwesomeIcon icon={faEdit} className="mr-1" />
                            </button>
                          </div>
                          <span className="text-xs text-gray-500">
                            {referral?.createdAt
                              ? new Date(referral.createdAt).toLocaleDateString('en-US', {
                                  day: '2-digit',
                                  month: 'long',
                                  year: 'numeric',
                                })
                              : 'No date available'}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No Referrals Found</p>
                  )}
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        )}
      </div>
      <AddReferralRequestModal
        toggleReferralRequestModal={toggleReferralRequestModal}
        requestReferralModalOpen={requestReferralModalOpen}
        selectedReferral={selectedReferral}
      />
    </MainLayout>
  );
};

export default ReferralRequests;
