import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Spin } from 'antd';
import DatePicker from 'react-datepicker';
import { fulfill_requested_referral_action } from '../../../../_redux/action';
import InputField from '../../../../_component/InputField';

const FulfillReferralModal = ({ referralDetails, fulfillReferralModalOpen, toggleFulfillReferralModalOpen }) => {
  const [fulfillReferralForm, setFulfillReferralForm] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFulfillReferralForm({
      ...fulfillReferralForm,
      [name]: value,
    });
  };

  const onFulfillReferralRequestClick = async () => {
    setLoading(true);
    await dispatch(
      fulfill_requested_referral_action({ ...fulfillReferralForm, referral_request_id: referralDetails?._id })
    );
    setLoading(false);
    setFulfillReferralForm({});
    toggleFulfillReferralModalOpen();
  };

  return (
    <Modal
      open={fulfillReferralModalOpen}
      onCancel={toggleFulfillReferralModalOpen}
      footer={[
        <Button key="save" onClick={() => onFulfillReferralRequestClick()}>
          Submit
        </Button>,
      ]}
      centered
      title="Fulfill Referral Request"
    >
      {loading ? (
        <div className="centered spinner-box">
          <Spin size="large" />
        </div>
      ) : (
        <div className="space-y-3">
          <div className="flex flex-col">
            <label className="mb-1 font-medium text-gray-700">
              <span className="text-red-500">*</span> Referral Date
            </label>
            <DatePicker
              selected={fulfillReferralForm?.referral_date || ''}
              placeholderText="Select"
              onChange={(date) => {
                handleFormChange({
                  target: { value: date, name: 'referral_date' },
                });
              }}
              showMonthDropdown
              showYearDropdown
              className="edit-input flex flex-col"
              filterDate={(date) => {
                const today = new Date();
                const fiveDaysFromNow = new Date();
                fiveDaysFromNow.setDate(today.getDate() + 5);
                return date >= today && date <= fiveDaysFromNow;
              }}
            />
          </div>

          <div className="flex flex-col">
            <InputField
              id="additional_notes"
              label="Useful Information"
              inputType="textarea"
              name="additional_notes"
              placeholder="Add additional notes"
              defaultValue={fulfillReferralForm?.additional_noted}
              onChange={handleFormChange}
              containerClassName="form-group  flex flex-col"
              inputClassName="rounded-2xl border border-gray-800 px-3 py-2 text-sm"
              labelClassName="text-gray-700"
              rows={3}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default FulfillReferralModal;
