import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetch_all_accepted_referrals_action, fetch_all_fulfilled_referrals_action, fetch_all_requested_referrals_action } from '../../../_redux/action';
import { navigateRoutes } from '../../../_config/route';

export default function useRequestedReferrals() {
  const [loading, setLoading] = useState(false);
  const [requestedReferrals, setRequestedReferrals] = useState([]);
  const [acceptedReferrals, setAcceptedReferrals] = useState([]);
  const [fulfilledReferrals, setFulfilledReferrals] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { requestedReferralsData } = useSelector((state) => state.fetch_all_requested_referrals_reducer);

  const { acceptedReferralsData } = useSelector((state) => state.fetch_all_accepted_referrals_reducer);

  const {fulfilledReferralsData} = useSelector((state) => state.fetch_all_fulfilled_referrals_reducer);

  useEffect(() => {
    if (requestedReferralsData) {
      setRequestedReferrals(requestedReferralsData);
    }
  }, [requestedReferralsData]);

  useEffect(() => {
    if (acceptedReferralsData) {
      setAcceptedReferrals(acceptedReferralsData);
    }
  }, [acceptedReferralsData]);

  useEffect(() => {
    if (fulfilledReferralsData) {
      setFulfilledReferrals(fulfilledReferralsData);
    }
  }, [fulfilledReferralsData]);

  useEffect(() => {
    const callRequestedReferralsApi = async () => {
      setLoading(true);
      await dispatch(fetch_all_requested_referrals_action());
      await dispatch(fetch_all_accepted_referrals_action());
      await dispatch(fetch_all_fulfilled_referrals_action());
      setLoading(false);
    };
    callRequestedReferralsApi();
  }, []);

  const onReferralPreferencesClick = () => {
    navigate(navigateRoutes.referralPreferences);
  };

  const onReferralDetailsClick = (referral) => { 
    if(referral?._id){
      navigate(navigateRoutes.referralDetails.replace(':id', referral?._id));
    }
  }

  return {
    loading,
    requestedReferrals,
    acceptedReferrals,
    fulfilledReferrals,

    onReferralPreferencesClick,
    onReferralDetailsClick
  };
}
