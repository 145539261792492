import { profileConstants } from '../../constant';

const initialState = { profile: null, candidatePublicProfileData: null };

export const fetch_candidate_profile_reducer = (state = initialState, action) => {
  switch (action.type) {
    case profileConstants.FETCH_CANDIDATE_PROFILE_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case profileConstants.FETCH_CANDIDATE_PROFILE_ACTION_SUCCESS:
    case profileConstants.UPDATE_CANDIDATE_PROFILE_ACTION_SUCCESS:
      return {
        profile: action.payload,
      };
    case profileConstants.FETCH_CANDIDATE_PROFILE_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const update_candidate_profile_reducer = (state = initialState, action) => {
  switch (action.type) {
    case profileConstants.UPDATE_CANDIDATE_PROFILE_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case profileConstants.UPDATE_CANDIDATE_PROFILE_ACTION_SUCCESS:
      return {
        profile: action.payload,
      };
    case profileConstants.UPDATE_CANDIDATE_PROFILE_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        message: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_candidate_public_profile_reducer = (state = initialState, action) => {
  switch (action.type) {
    case profileConstants.FETCH_CANDIDATE_PUBLIC_PROFILE_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case profileConstants.FETCH_CANDIDATE_PUBLIC_PROFILE_ACTION_SUCCESS:
      return {
        candidatePublicProfileData: action.payload,
      };
    case profileConstants.FETCH_CANDIDATE_PUBLIC_PROFILE_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};