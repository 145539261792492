import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetch_user_referral_requests_action } from '../../../_redux/action';
import { navigateRoutes } from '../../../_config/route';

export default function useReferralRequests(props) {
  const [loading, setLoading] = useState(false);
  const [referralRequests, setReferralRequests] = useState([]);
  const [requestReferralModalOpen, setRequestReferralModalOpen] = useState(false);
  const [selectedReferral, setSelectedReferral] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { referralRequestsData } = useSelector((state) => state.fetch_user_referral_requests_reducer);

  useEffect(() => {
    if (referralRequestsData) {
      setReferralRequests(referralRequestsData);
    }
  }, [referralRequestsData]);

  useEffect(() => {
    const callRequestedReferralsApi = async () => {
      setLoading(true);
      await dispatch(fetch_user_referral_requests_action());
      setLoading(false);
    };
    callRequestedReferralsApi();
  }, []);

  const toggleReferralRequestModal = async (referral) => {
    setRequestReferralModalOpen((v) => !v);
    await dispatch(fetch_user_referral_requests_action());
    setSelectedReferral(referral);
  };

  const onReferralDetailsClick = (referral) => {
    if (referral?._id) {
      navigate(navigateRoutes.referralDetails.replace(':id', referral?._id));
    }
  };

  const onCurrentTabChange = async (tab) => {
    if (tab === 'all') {
      await dispatch(fetch_user_referral_requests_action());
    } else if (tab) {
      await dispatch(fetch_user_referral_requests_action(tab));
    }
  };

  return {
    loading,
    referralRequests,
    selectedReferral,
    requestReferralModalOpen,
    toggleReferralRequestModal,
    onReferralDetailsClick,
    onCurrentTabChange
  };
}
