import React from 'react';
import MainLayout from '../../../_layout/MainLayout';
import useReferralDetails from './useReferralDetails';
import { referralStatus } from '../../../_helpers/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faLayerGroup, faLocationDot, faMessage } from '@fortawesome/free-solid-svg-icons';
import AcceptReferralModal from './_components/AcceptReferralModal';
import FulfillReferralModal from './_components/FulfillReferralModal';
import { Spin } from 'antd';

function ReferralDetails(props) {
  const {
    loading,
    user,
    requestedReferralDetails,
    acceptReferralModalOpen,
    fulfillReferralModalOpen,
    handleJobViewClick,
    toggleAcceptReferralModalOpen,
    filterAcceptedReferral,
    toggleFulfillReferralModalOpen,
    handleCandidateProfileClick
  } = useReferralDetails(props);

  return (
    <MainLayout activeMenuItem="home">
      {loading ? (
        <div className="centered spinner-box">
          <Spin size="large" />
        </div>
      ) : (
        <div className="p-4 sticky">
          <h2 className="text-2xl font-medium p-1">
            {requestedReferralDetails?.user_id?._id !== user?.user?._id
              ? `Referral Request by: ${requestedReferralDetails?.user_id?.first_name}`
              : 'Your Referral Request'}

            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="h-4 mx-2 text-[#2F6D6A]" onClick={() => handleCandidateProfileClick()} />
          </h2>
          <hr />
          <div className="flex items-center justify-between mt-2">
            <div>
              <h2 className="text-dark text-lg font-medium">{requestedReferralDetails?.job_id?.title}</h2>
              <p className="text-[#2F6D6A] font-medium">
                {requestedReferralDetails?.company_id?.company_name}{' '}
                <button className="ml-2" onClick={() => handleJobViewClick()}>
                  {' '}
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="h-4 text-[#2F6D6A]" />
                </button>
              </p>
              <div className="flex flex-row gap-4 items-center mt-1">
                <p className="text-xs">
                  <FontAwesomeIcon icon={faLocationDot} className="mr-0.5" />{' '}
                  {requestedReferralDetails?.country_id?.display_name || 'N/A'}
                </p>
                <p className="text-xs">
                  <FontAwesomeIcon icon={faLayerGroup} className="mr-0.5" />{' '}
                  {requestedReferralDetails?.seniority_level?.replace(/^\w/, (c) => c.toUpperCase()) || 'N/A'}
                </p>
              </div>
              <p className="text-xs mt-2">
                <FontAwesomeIcon icon={faMessage} className="mr-0.3" /> {requestedReferralDetails?.request_message}
              </p>
            </div>
            <div>
              <div className="my-2 flex flex-col items-end gap-1">
                <div className="Job-type-tags flex flex-row gap-2">
                  <span className="p-1.5 rounded-lg bg-[#FFE0A9] text-xs">
                    {requestedReferralDetails?.status && requestedReferralDetails.status.toUpperCase()}
                  </span>
                </div>
                {requestedReferralDetails?.status === referralStatus?.pending &&
                  requestedReferralDetails?.user_id?._id !== user?.user?._id && (
                    <button
                      onClick={() => toggleAcceptReferralModalOpen()}
                      className="font-medium py-1 px-2 bg-[#2F6D6A] text-white rounded-full"
                    >
                      Accept Referral
                    </button>
                  )}

                {filterAcceptedReferral() ? (
                  <button
                    onClick={() => toggleFulfillReferralModalOpen()}
                    className="font-medium py-1 px-2 bg-[#2F6D6A] text-white rounded-full"
                  >
                    Fullfill Referral
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          <hr />

          <div className="mt-4">
            <h2 className="text-lg my-1 font-semibold">Job Description</h2>
            <div dangerouslySetInnerHTML={{ __html: requestedReferralDetails?.job_id?.job_details }} />
          </div>
        </div>
      )}

      <AcceptReferralModal
        toggleAcceptReferralModalOpen={toggleAcceptReferralModalOpen}
        acceptReferralModalOpen={acceptReferralModalOpen}
        referralDetails={requestedReferralDetails}
      />

      <FulfillReferralModal
        referralDetails={requestedReferralDetails}
        fulfillReferralModalOpen={fulfillReferralModalOpen}
        toggleFulfillReferralModalOpen={toggleFulfillReferralModalOpen}
      />
    </MainLayout>
  );
}

export default ReferralDetails;
