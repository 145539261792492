import { userReferralConstants } from '../../constant';

const initialState = { referralRequestsData: null };
export const fetch_user_referral_requests_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userReferralConstants.FETCH_USER_REFERRAL_REQUESTS_ACTION_REQUEST:
      return {
        ...state,
        type: 'alert-success',
        message: action.payload,
      };
    case userReferralConstants.FETCH_USER_REFERRAL_REQUESTS_ACTION_SUCCESS:
      return {
        ...state,
        referralRequestsData: action.payload,
      };
    case userReferralConstants.FETCH_USER_REFERRAL_REQUESTS_ACTION_FAILURE:
      return {
        ...state,
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};
