import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetch_user_referral_preferences_action } from '../../../_redux/action';

export default function useReferralPreferences() {
  const [loading, setLoading] = useState(false);
  const [referralPreferences, setReferralPreferences] = useState([]);
  const [requestReferralModalOpen, setRequestReferralModalOpen] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { referralPreferencesData } = useSelector((state) => state.fetch_user_referral_preferences_reducer);

  useEffect(() => {
    if (referralPreferencesData) {
      setReferralPreferences(referralPreferencesData);
    }
  }, [referralPreferencesData]);

  useEffect(() => {
    const callReferralPreferencesApi = async () => {
      setLoading(true);
      await dispatch(fetch_user_referral_preferences_action());
      setLoading(false);
    };
    callReferralPreferencesApi();
  }, []);

  const toggleReferralPreferenceModalOpen = async (referralPreference) => {
    setRequestReferralModalOpen((v) => !v);
    await dispatch(fetch_user_referral_preferences_action());

    setSelectedPreference(referralPreference);
  };

  return {
    loading,
    referralPreferences,
    requestReferralModalOpen,
    selectedPreference,
    toggleReferralPreferenceModalOpen,
  };
}
