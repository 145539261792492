/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBriefcase,
  faSignOut,
  faEllipsisV,
  faBarsStaggered,
  faXmarkCircle,
  faUserFriends,
  faTachometerAlt,
  faFileAlt,
  faMicrophone,
  faBuilding,
  faAddressBook,
} from '@fortawesome/free-solid-svg-icons';
import './Sidebar.scss';
import useSidebar from './useSidebar';
import { navigateRoutes } from '../../_config/route';

// Reusable Component for Navigation Links
const NavLink = ({ icon, label, onClick, isActive }) => (
  <button onClick={onClick} className={`sidebar-link ${isActive ? 'active-route' : ''}`}>
    <div className="icon">
      <FontAwesomeIcon icon={icon} />
    </div>
    <span>{label}</span>
  </button>
);

// Reusable Component for Drawer Links
const DrawerLink = ({ icon, label, onClick, isActive }) => (
  <button onClick={onClick} className={`drawer-link ${isActive ? 'active-route' : ''}`}>
    <div className="icon">
      <FontAwesomeIcon icon={icon} />
    </div>
    <span>{label}</span>
  </button>
);

// Main Sidebar Component
export default (children) => {
  const {
    user,
    loading,
    isCollapsed,
    isDropdownOpen,
    dropdownRef,
    isDrawerOpen,

    location,

    viewProfile,
    toggleDrawer,
    onSignOutClick,
    toggleSidebar,
    handleToggleDropdown,
    handleNavigation,
  } = useSidebar();

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : 'expanded'}`}>
      {/* Company Section */}
      <div className="company centered">
        <span className="icon">Interview&</span>
        <button className="centered menu-bar" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faBarsStaggered} />
        </button>
        <button className="centered drawer-bar" onClick={toggleDrawer}>
          <FontAwesomeIcon icon={faBarsStaggered} />
        </button>
      </div>

      {/* Inner Navigation Links */}
      <div className="inner drawer">
        <NavLink
          icon={faTachometerAlt}
          label="Referrals"
          onClick={() => handleNavigation(navigateRoutes.candidateDashboard)}
          isActive={location.pathname === navigateRoutes.candidateDashboard}
        />
        <NavLink
          icon={faBriefcase}
          label="Jobs Handler"
          onClick={() => handleNavigation(navigateRoutes.jobTracker)}
          isActive={location.pathname === navigateRoutes.jobTracker}
        />
        <NavLink
          icon={faFileAlt}
          label="Resume Builder"
          onClick={() => handleNavigation(navigateRoutes.resumeBuilder)}
          isActive={location.pathname === navigateRoutes.resumeBuilder}
        />
        <NavLink
          icon={faMicrophone}
          label="Mock Interviews"
          onClick={() => handleNavigation(navigateRoutes.mockInterviews)}
          isActive={location.pathname === navigateRoutes.mockInterviews}
        />
        <NavLink
          icon={faBuilding}
          label="Companies"
          onClick={() => handleNavigation(navigateRoutes.savedCompanies)}
          isActive={location.pathname === navigateRoutes.savedCompanies}
        />
        <NavLink
          icon={faAddressBook}
          label="Contacts"
          onClick={() => handleNavigation(navigateRoutes.savedContacts)}
          isActive={location.pathname === navigateRoutes.savedContacts}
        />
      </div>

      {/* Bottom Section */}
      <div className="bottom drawer">
        <div className="user centered">
          <div className="user-img" onClick={isCollapsed ? viewProfile : null}>
            <img src={user?.user.profile_image} alt="user" />
          </div>
          {!isCollapsed && (
            <>
              <div className="content flex-grow">
                <p className="mb-0">{user?.user.first_name}</p>
                <p className="mb-0 text-gray-500">{user?.user.email}</p>
              </div>
              <div className="relative" ref={dropdownRef}>
                <button onClick={handleToggleDropdown} className="p-2">
                  <FontAwesomeIcon icon={faEllipsisV} />
                </button>
                {isDropdownOpen && (
                  <div
                    className="dropdown-menu"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex="-1"
                  >
                    <div className="py-1">
                      <button onClick={viewProfile} className="dropdown-item">
                        My Profile
                      </button>
                      <button onClick={onSignOutClick} className="dropdown-item">
                        {loading ? (
                          <div className="loader centered w-full"></div>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faSignOut} className="mr-2" />
                            Logout
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {/* Drawer Slide-In */}
      {isDrawerOpen && (
        <div className={`drawer-slide ${isDrawerOpen ? 'open' : ''}`}>
          <div className="close-drawer" onClick={toggleDrawer}>
            <FontAwesomeIcon icon={faXmarkCircle} />
          </div>
          <div className="inner">
            <DrawerLink
              icon={faTachometerAlt}
              label="Referrals"
              onClick={() => handleNavigation(navigateRoutes.candidateDashboard)}
              isActive={location.pathname === navigateRoutes.candidateDashboard}
            />
            <DrawerLink
              icon={faBriefcase}
              label="Jobs Handler"
              onClick={() => handleNavigation(navigateRoutes.jobTracker)}
              isActive={location.pathname === navigateRoutes.jobTracker}
            />
            <DrawerLink
              icon={faFileAlt}
              label="Resume Builder"
              onClick={() => handleNavigation(navigateRoutes.resumeBuilder)}
              isActive={location.pathname === navigateRoutes.resumeBuilder}
            />
            <DrawerLink
              icon={faMicrophone}
              label="Mock Interviews"
              onClick={() => handleNavigation(navigateRoutes.mockInterviews)}
              isActive={location.pathname === navigateRoutes.mockInterviews}
            />
            <DrawerLink
              icon={faBuilding}
              label="Companies"
              onClick={() => handleNavigation(navigateRoutes.savedCompanies)}
              isActive={location.pathname === navigateRoutes.savedCompanies}
            />
            <DrawerLink
              icon={faAddressBook}
              label="Contacts"
              onClick={() => handleNavigation(navigateRoutes.savedContacts)}
              isActive={location.pathname === navigateRoutes.savedContacts}
            />
          </div>
          <div className="bottom">
            <div className="user centered">
              <div className="user-img" onClick={viewProfile}>
                <img src={user?.user.profile_image} alt="user" />
              </div>
              <div className="drawer-content flex-grow">
                <p className="mb-0">{user?.user.first_name}</p>
                <p className="mb-0 text-gray-500">{user?.user.email}</p>
              </div>
              <div className="relative" ref={dropdownRef}>
                <button onClick={handleToggleDropdown} className="p-2">
                  <FontAwesomeIcon icon={faEllipsisV} />
                </button>
                {isDropdownOpen && (
                  <div
                    className="dropdown-menu"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex="-1"
                  >
                    <div className="py-1">
                      <button onClick={viewProfile} className="dropdown-item">
                        My Profile
                      </button>
                      <button onClick={onSignOutClick} className="dropdown-item">
                        {loading ? (
                          <div className="loader centered w-full"></div>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faSignOut} className="mr-2" />
                            Logout
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
