/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetch_candidate_public_profile_action } from '../../../_redux/action';

function useCandidatePublicProfile(props) {
  const [loading, setLoading] = useState(false);
  const [candidatePublicProfile, setCandidatePublicProfile] = useState({});

  const { candidatePublicProfileData } = useSelector((state) => state.fetch_candidate_public_profile_reducer);

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    const callCandidatePublicProfileApi = async (id) => {

      setLoading(true);
      await dispatch(fetch_candidate_public_profile_action(id));
      setLoading(false);
    };

    if (params?.id) {
      callCandidatePublicProfileApi(params.id);
    }
  }, [params]);

  useEffect(() => {
    if (candidatePublicProfileData) {
      setCandidatePublicProfile(candidatePublicProfileData);
    }
  }, [candidatePublicProfileData]);

  return {
    candidatePublicProfile,
    loading,
  };
}
export default useCandidatePublicProfile;
