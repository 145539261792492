import { masterConstants } from '../../constant';

const initialState = {
  skills: null,
  pronouns: null,
  headlines: null,
  colleges: null,
  cities: null,
  regions: null,
  countries: null,
  companies: null,
  courses: null,
  courseTypes: null,
  companyData: null,
};

export const fetch_skills_reducer = (state = initialState, action) => {
  switch (action.type) {
    case masterConstants.FETCH_SKILLS_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case masterConstants.FETCH_SKILLS_ACTION_SUCCESS:
      return {
        skills: action.payload,
      };
    case masterConstants.FETCH_SKILLS_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_headlines_reducer = (state = initialState, action) => {
  switch (action.type) {
    case masterConstants.FETCH_HEADLINES_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case masterConstants.FETCH_HEADLINES_ACTION_SUCCESS:
      return {
        headlines: action.payload,
      };
    case masterConstants.FETCH_HEADLINES_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_pronouns_reducer = (state = initialState, action) => {
  switch (action.type) {
    case masterConstants.FETCH_PRONOUNS_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case masterConstants.FETCH_PRONOUNS_ACTION_SUCCESS:
      return {
        pronouns: action.payload,
      };
    case masterConstants.FETCH_PRONOUNS_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_colleges_reducer = (state = initialState, action) => {
  switch (action.type) {
    case masterConstants.FETCH_COLLEGES_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case masterConstants.FETCH_COLLEGES_ACTION_SUCCESS:
      return {
        colleges: action.payload,
      };
    case masterConstants.FETCH_COLLEGES_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_cities_reducer = (state = initialState, action) => {
  switch (action.type) {
    case masterConstants.FETCH_CITIES_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case masterConstants.FETCH_CITIES_ACTION_SUCCESS:
      return {
        cities: action.payload,
      };
    case masterConstants.FETCH_CITIES_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_regions_reducer = (state = initialState, action) => {
  switch (action.type) {
    case masterConstants.FETCH_REGIONS_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case masterConstants.FETCH_REGIONS_ACTION_SUCCESS:
      return {
        regions: action.payload,
      };
    case masterConstants.FETCH_REGIONS_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_countries_reducer = (state = initialState, action) => {
  switch (action.type) {
    case masterConstants.FETCH_COUNTRIES_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case masterConstants.FETCH_COUNTRIES_ACTION_SUCCESS:
      return {
        countries: action.payload,
      };
    case masterConstants.FETCH_COUNTRIES_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_companies_reducer = (state = initialState, action) => {
  switch (action.type) {
    case masterConstants.FETCH_COMPANIES_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case masterConstants.FETCH_COMPANIES_ACTION_SUCCESS:
      return {
        companies: action.payload,
      };
    case masterConstants.FETCH_COMPANIES_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_courses_reducer = (state = initialState, action) => {
  switch (action.type) {
    case masterConstants.FETCH_COURSES_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case masterConstants.FETCH_COURSES_ACTION_SUCCESS:
      return {
        courses: action.payload,
      };
    case masterConstants.FETCH_COURSES_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_course_types_reducer = (state = initialState, action) => {
  switch (action.type) {
    case masterConstants.FETCH_COURSE_TYPES_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case masterConstants.FETCH_COURSE_TYPES_ACTION_SUCCESS:
      return {
        courseTypes: action.payload,
      };
    case masterConstants.FETCH_COURSE_TYPES_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};
