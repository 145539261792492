import { userReferralConstants } from '../../constant';

const initialState = { referralPreferencesData: null };


export const fetch_user_referral_preferences_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userReferralConstants.FETCH_USER_REFERRAL_PREFERENCES_ACTION_REQUEST:
      return {
        ...state,
        type: 'alert-success',
        message: action.payload,
      };

    case userReferralConstants.FETCH_USER_REFERRAL_PREFERENCES_ACTION_ACTION_SUCCESS:
      return {
        ...state,
        referralPreferencesData: action.payload,
      };

    case userReferralConstants.FETCH_USER_REFERRAL_PREFERENCES_ACTION_ACTION_FAILURE:
      return {
        ...state,
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};
