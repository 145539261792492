import React from 'react';
import MainLayout from '../../../_layout/MainLayout';
import useReferralPreferences from './useReferralPreferences';
import { Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faEdit, faLevelUp, faLocation } from '@fortawesome/free-solid-svg-icons';
import AddReferralPreferenceModal from '../../../_component/AddReferralPreferenceModal';

const ReferralPreferences = () => {
  const {
    loading,
    referralPreferences,
    requestReferralModalOpen,
    selectedPreference,
    toggleReferralPreferenceModalOpen,
  } = useReferralPreferences();

  return (
    <MainLayout activeMenuItem="home">
      <div className="p-4 sticky h-screen">
        <div className="centered justify-between mb-4 window-container">
          <h2 className="window-title">Referral Preferences</h2>
          <div className="centered justify-center ">
            <div className="centered">
              <button
                className="btn bg-slate-50 text-gray-900 rounded-xl outline outline-1 hover:bg-slate-200"
                onClick={() => toggleReferralPreferenceModalOpen(null)}
              >
                + Add Referral Preference
              </button>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="centered h-[80vh] spinner-box">
            <Spin size="large" />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pb-4">
            {(referralPreferences || []).length > 0 ? (
              (referralPreferences || []).map((referralPreference) => (
                <div
                  key={referralPreference.id}
                  className="border border-[#e9e7e4] shadow-[5px_#0003] rounded-2xl bg-[#faf9f8] p-4 flex flex-col gap-4"
                >
                  <div className="flex items-center flex-row justify-between bg-white p-3 rounded-xl border">
                    <div className="flex flex-col items-start">
                      <h3 className="text-lg font-medium tracking-tight">
                        <FontAwesomeIcon icon={faBuilding} className="mr-1" />
                        {referralPreference?.company_id?.company_name}
                      </h3>
                      <span>
                        <FontAwesomeIcon icon={faLevelUp} className="mr-1 text-xs" />
                        {referralPreference?.seniority_level}
                      </span>
                      <span>
                        <FontAwesomeIcon icon={faLocation} className="mr-1 text-xs" />
                        {referralPreference?.country_id?.display_name}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <button
                        className="p-1 btn btn-primary text-gray-900 hover:bg-slate-200"
                        onClick={() => toggleReferralPreferenceModalOpen(referralPreference)}
                      >
                        <FontAwesomeIcon icon={faEdit} className="mr-1" />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="centered">No Referral Preferences Found.</div>
            )}
          </div>
        )}
      </div>

      <AddReferralPreferenceModal
        toggleReferralPreferenceModalOpen={toggleReferralPreferenceModalOpen}
        referralPreferenceModalOpen={requestReferralModalOpen}
        selectedPreference={selectedPreference}
      />
    </MainLayout>
  );
};

export default ReferralPreferences;
