export const userReferralConstants = {
  FETCH_USER_REFERRAL_REQUESTS_ACTION_REQUEST: 'FETCH_USER_REFERRAL_REQUESTS_ACTION_REQUEST',
  FETCH_USER_REFERRAL_REQUESTS_ACTION_SUCCESS: 'FETCH_USER_REFERRAL_REQUESTS_ACTION_SUCCESS',
  FETCH_USER_REFERRAL_REQUESTS_ACTION_FAILURE: 'FETCH_USER_REFERRAL_REQUESTS_ACTION_FAILURE',

  FETCH_ALL_REQUESTED_REFERRAL_ACTION_REQUEST: 'FETCH_ALL_REQUESTED_REFERRAL_ACTION_REQUEST',
  FETCH_ALL_REQUESTED_REFERRAL_ACTION_SUCCESS: 'FETCH_ALL_REQUESTED_REFERRAL_ACTION_SUCCESS',
  FETCH_ALL_REQUESTED_REFERRAL_ACTION_FAILURE: 'FETCH_ALL_REQUESTED_REFERRAL_ACTION_FAILURE',

  FETCH_USER_REFERRAL_PREFERENCES_ACTION_REQUEST: 'FETCH_USER_REFERRAL_PREFERENCES_ACTION_REQUEST',
  FETCH_USER_REFERRAL_PREFERENCES_ACTION_ACTION_SUCCESS: 'FETCH_USER_REFERRAL_PREFERENCES_ACTION_ACTION_SUCCESS',
  FETCH_USER_REFERRAL_PREFERENCES_ACTION_ACTION_FAILURE: 'FETCH_USER_REFERRAL_PREFERENCES_ACTION_ACTION_FAILURE',

  CREATE_REFERRAL_REQUEST_ACTION_REQUEST: 'CREATE_REFERRAL_REQUEST_ACTION_REQUEST',
  CREATE_REFERRAL_REQUEST_ACTION_SUCCESS: 'CREATE_REFERRAL_REQUEST_ACTION_SUCCESS',
  CREATE_REFERRAL_REQUEST_ACTION_FAILURE: 'CREATE_REFERRAL_REQUEST_ACTION_FAILURE',

  UPDATE_REFERRAL_REQUEST_ACTION_REQUEST: 'UPDATE_REFERRAL_REQUEST_ACTION_REQUEST',
  UPDATE_REFERRAL_REQUEST_ACTION_SUCCESS: 'UPDATE_REFERRAL_REQUEST_ACTION_SUCCESS',
  UPDATE_REFERRAL_REQUEST_ACTION_FAILURE: 'UPDATE_REFERRAL_REQUEST_ACTION_FAILURE',

  CREATE_REFERRAL_PREFERENCE_ACTION_REQUEST: 'CREATE_REFERRAL_PREFERENCE_ACTION_REQUEST',
  CREATE_REFERRAL_PREFERENCE_ACTION_SUCCESS: 'CREATE_REFERRAL_PREFERENCE_ACTION_SUCCESS',
  CREATE_REFERRAL_PREFERENCE_ACTION_FAILURE: 'CREATE_REFERRAL_PREFERENCE_ACTION_FAILURE',

  UPDATE_REFERRAL_PREFERENCE_ACTION_REQUEST: 'UPDATE_REFERRAL_PREFERENCE_ACTION_REQUEST',
  UPDATE_REFERRAL_PREFERENCE_ACTION_SUCCESS: 'UPDATE_REFERRAL_PREFERENCE_ACTION_SUCCESS',
  UPDATE_REFERRAL_PREFERENCE_ACTION_FAILURE: 'UPDATE_REFERRAL_PREFERENCE_ACTION_FAILURE',

  FETCH_ALL_ACCEPTED_REFERRAL_ACTION_REQUEST: 'FETCH_ALL_ACCEPTED_REFERRAL_ACTION_REQUEST',
  FETCH_ALL_ACCEPTED_REFERRAL_ACTION_SUCCESS: 'FETCH_ALL_ACCEPTED_REFERRAL_ACTION_SUCCESS',
  FETCH_ALL_ACCEPTED_REFERRAL_ACTION_FAILURE: 'FETCH_ALL_ACCEPTED_REFERRAL_ACTION_FAILURE',

  FETCH_ALL_FULFILLED_REFERRAL_ACTION_REQUEST: 'FETCH_ALL_FULFILLED_REFERRAL_ACTION_REQUEST',
  FETCH_ALL_FULFILLED_REFERRAL_ACTION_SUCCESS: 'FETCH_ALL_FULFILLED_REFERRAL_ACTION_SUCCESS',
  FETCH_ALL_FULFILLED_REFERRAL_ACTION_FAILURE: 'FETCH_ALL_FULFILLED_REFERRAL_ACTION_FAILURE',

  FETCH_REQUESTED_REFERRAL_DETAILS_ACTION_REQUEST: 'FETCH_REQUESTED_REFERRAL_DETAILS_ACTION_REQUEST',
  FETCH_REQUESTED_REFERRAL_DETAILS_ACTION_SUCCESS: 'FETCH_REQUESTED_REFERRAL_DETAILS_ACTION_SUCCESS',
  FETCH_REQUESTED_REFERRAL_DETAILS_ACTION_FAILURE: 'FETCH_REQUESTED_REFERRAL_DETAILS_ACTION_FAILURE',

  ACCEPT_REQUESTED_REFERRAL_ACTION_REQUEST: 'ACCEPT_REQUESTED_REFERRAL_ACTION_REQUEST',
  ACCEPT_REQUESTED_REFERRAL_ACTION_SUCCESS: 'ACCEPT_REQUESTED_REFERRAL_ACTION_SUCCESS',
  ACCEPT_REQUESTED_REFERRAL_ACTION_FAILURE: 'ACCEPT_REQUESTED_REFERRAL_ACTION_FAILURE',

  FULFILL_REQUESTED_REFERRAL_ACTION_REQUEST: 'FULFILL_REQUESTED_REFERRAL_ACTION_REQUEST',
  FULFILL_REQUESTED_REFERRAL_ACTION_SUCCESS: 'FULFILL_REQUESTED_REFERRAL_ACTION_SUCCESS',
  FULFILL_REQUESTED_REFERRAL_ACTION_FAILURE: 'FULFILL_REQUESTED_REFERRAL_ACTION_FAILURE',
};
