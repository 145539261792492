import { userReferralConstants } from '../../constant';

const initialState = { requestededReferrals: null, acceptedReferrals: null };

export const fetch_all_requested_referrals_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userReferralConstants.FETCH_ALL_REQUESTED_REFERRAL_ACTION_REQUEST:
      return {
        ...state,
        type: 'alert-success',
        message: action.payload,
      };

    case userReferralConstants.FETCH_ALL_REQUESTED_REFERRAL_ACTION_SUCCESS:
      return {
        ...state,
        requestedReferralsData: action.payload,
      };

    case userReferralConstants.FETCH_ALL_REQUESTED_REFERRAL_ACTION_FAILURE:
      return {
        ...state,
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_all_accepted_referrals_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userReferralConstants.FETCH_ALL_ACCEPTED_REFERRAL_ACTION_REQUEST:
      return {
        ...state,
        type: 'alert-success',
        message: action.payload,
      };

    case userReferralConstants.FETCH_ALL_ACCEPTED_REFERRAL_ACTION_SUCCESS:
      return {
        ...state,
        acceptedReferralsData: action.payload,
      };

    case userReferralConstants.FETCH_ALL_ACCEPTED_REFERRAL_ACTION_FAILURE:
      return {
        ...state,
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_all_fulfilled_referrals_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userReferralConstants.FETCH_ALL_FULFILLED_REFERRAL_ACTION_REQUEST:
      return {
        ...state,
        type: 'alert-success',
        message: action.payload,
      };

    case userReferralConstants.FETCH_ALL_FULFILLED_REFERRAL_ACTION_SUCCESS:
      return {
        ...state,
        fulfilledReferralsData: action.payload,
      };

    case userReferralConstants.FETCH_ALL_FULFILLED_REFERRAL_ACTION_FAILURE:
      return {
        ...state,
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_requested_referral_details_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userReferralConstants.FETCH_REQUESTED_REFERRAL_DETAILS_ACTION_REQUEST:
      return {
        ...state,
        type: 'alert-success',
        message: action.payload,
      };

    case userReferralConstants.FETCH_REQUESTED_REFERRAL_DETAILS_ACTION_SUCCESS:
      return {
        ...state,
        requestedReferralDetailsData: action.payload,
      };

    case userReferralConstants.FETCH_REQUESTED_REFERRAL_DETAILS_ACTION_FAILURE:
      return {
        ...state,
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};
