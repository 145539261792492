/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import useCandidatePublicProfile from './useCandidatePublicProfile';
import MainLayout from '../../../_layout/MainLayout';
import { Spin,  } from 'antd';
import { dateFormatter } from '../../../_helpers/dateFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faStar,  } from '@fortawesome/free-solid-svg-icons';

export default (props) => {
  const { candidatePublicProfile, loading } = useCandidatePublicProfile(props);

  const { profile, skills, experiences, educations } = candidatePublicProfile;

  return (
    <MainLayout activeMenuItem="candidate-profile">
      <div className="p-4 sticky h-screen">
        <div className="centered justify-between mb-4 window-container">
          <h2 className="window-title">{profile?.candidate?.first_name} Profile</h2>
        </div>
        <div className="flex flex-col items-center">
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <Spin size="large" />
            </div>
          ) : (
            <div className="w-full max-w-4xl bg-white p-6 rounded-lg shadow-md">
              <div className="flex flex-row justify-start items-center gap-4 mb-6">
                <div>
                  <img src={profile?.candidate?.profile_image} className="rounded-full h-24 w-24" alt="Profile" />
                </div>
                <div>
                  <p className="text-xl font-semibold text-gray-900">
                    {profile?.candidate?.first_name} {profile?.candidate?.last_name}
                  </p>
                  <div className="space-x-3 flex flex-row items-center mt-2">
                    <a
                      href={profile?.fiverr}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      Fiverr
                    </a>
                    <a
                      href={profile?.github}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      Github
                    </a>
                    <a
                      href={profile?.portfolio}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      Portfolio
                    </a>
                  </div>
                  <div className="mt-2">
                    <FontAwesomeIcon icon={faLocationDot} className="mr-2 text-gray-600" />
                    <span className="text-sm text-gray-600">
                      {profile?.city?.display_name}, {profile?.region?.display_name}, {profile?.country?.display_name}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-4">Candidate Skills</h3>
                <div className="flex flex-wrap gap-4">
                  {Array.isArray(skills) && skills.length > 0 ? (
                    skills.map((item, index) => (
                      <div key={index} className="p-2 bg-gray-100 rounded-lg flex flex-row items-center">
                        <span>{item?.skill && item.skill?.display_name}</span>
                        <span className="ml-2 bg-white p-1">{item?.rating}<FontAwesomeIcon icon={faStar} className="ml-2 text-gray-600" /></span>
                      </div>
                    ))
                  ) : (
                    <p>No skills added.</p>
                  )}
                </div>
              </div>

              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-4">Professional Information</h3>
                <div>
                  {Array.isArray(experiences) && experiences.length > 0 ? (
                    experiences.map((item) => (
                      <div key={item._id} className="mb-4 p-4 bg-gray-100 rounded-lg">
                        <h4 className="text-lg font-semibold">{item?.company && item.company?.company_name}</h4>
                        <p className="text-sm text-gray-700">{item?.headline}</p>
                        <p className="text-sm text-gray-600">
                          {item?.start_date && dateFormatter(item.start_date)} -{' '}
                          {item?.is_current_company ? 'Present' : item?.end_date && dateFormatter(item.end_date)}
                        </p>
                        <p className="text-sm text-gray-700">{item?.description}</p>
                      </div>
                    ))
                  ) : (
                    <p>No experience added. Please add experience.</p>
                  )}
                </div>
              </div>

              <div>
                <h3 className="text-xl font-semibold mb-4">Education Information</h3>
                <div>
                  {Array.isArray(educations) && educations.length > 0 ? (
                    educations.map((item) => (
                      <div key={item._id} className="mb-4 p-4 bg-gray-100 rounded-lg">
                        <h4 className="text-lg font-semibold">{item?.college && item.college?.college_name}</h4>
                        <p className="text-sm text-gray-700">{item?.course_type && item.course_type?.display_name}</p>
                        <p className="text-sm text-gray-600">
                          {item?.major && item.major?.display_name} - GPA: {item?.gpa}
                        </p>
                        <p className="text-sm text-gray-700">{item?.end_date && dateFormatter(item.end_date)}</p>
                      </div>
                    ))
                  ) : (
                    <p>No education added. Please add education.</p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};
