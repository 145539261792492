export const navigateRoutes = {
  signIn: '/sign-in',
  signUp: '/sign-up',
  resetPassword: '/reset-password/:id',
  forgotPassword: '/forgot-password',

  candidateProfile: '/candidate-profile',
  candidateDashboard: '/candidate-dashboard',
  candidatePublicProfile: '/candidate-public-profile/:id',

  verifyEmail: '/verify-email',

  jobTracker: '/job-tracker',
  jobDeails: '/job-details/:id',

  resumeBuilder: '/resume-builder',
  fineTunedResume: '/review-resume/:id',

  requestedReferrals: '/requested-referrals',
  referralRequests: '/referral-requests',
  referralPreferences: '/referral-preferences',
  referralDetails: '/referral-details/:id',

  savedContacts: '/saved-contacts',
  savedCompanies: '/saved-companies',

  mockInterviews: '/mock-interviews',
  takeMockInterview: '/mock-interview/:id',
};
