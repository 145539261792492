import axiosInstance from '../../../_config/AxiosInstance';
import config from '../../../_config/config';
import { userReferralConstants } from '../../constant';
import { showWarningMessage } from '../../../_component/MessageSnack';

export const fetch_user_referral_preferences_action = () => async (dispatch) => {
  dispatch({
    type: userReferralConstants.FETCH_USER_REFERRAL_PREFERENCES_ACTION_REQUEST,
  });

  try {
    const response = await axiosInstance.get(config.referral.allReferralPreferences);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userReferralConstants.FETCH_USER_REFERRAL_PREFERENCES_ACTION_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userReferralConstants.FETCH_USER_REFERRAL_PREFERENCES_ACTION_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

// post request for create referral preferences
export const create_referral_preferences_action = (form) => async (dispatch) => {
  dispatch({
    type: userReferralConstants.CREATE_REFERRAL_PREFERENCE_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.referral.referralPreference, form);

    if (response.status) {
      let data = response.data;
      dispatch({
        type: userReferralConstants.CREATE_REFERRAL_PREFERENCE_ACTION_SUCCESS,
        payload: data.data,
      });
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userReferralConstants.CREATE_REFERRAL_PREFERENCE_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

// put request for update referral preferences
export const update_referral_preferences_action = (id, formData) => async (dispatch) => {
  dispatch({
    type: userReferralConstants.UPDATE_REFERRAL_PREFERENCE_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(`${config.referral.referralPreference}/${id}`, formData);

    if (response.status) {
      let data = response.data;
      dispatch({
        type: userReferralConstants.UPDATE_REFERRAL_PREFERENCE_ACTION_SUCCESS,
        payload: data.data,
      });
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userReferralConstants.UPDATE_REFERRAL_PREFERENCE_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};
