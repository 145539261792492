import React, { useEffect, useState } from 'react';
import InputField from './InputField';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { jobApplyStatus } from '../_helpers/types';
import {
  create_referral_request_action,
  fetch_user_bookmark_jobs_action,
  update_referral_request_action,
} from '../_redux/action';
import { Button, Modal, Spin } from 'antd';
import CompanySearchBasic from './Company';
import SeniorityLevelBasic from './SeniorityLevel';
import CreateCompany from './CreateCompany';
import CountrySearchBasic from './Country';

const AddReferralRequestModal = ({ requestReferralModalOpen, selectedReferral, toggleReferralRequestModal }) => {
  const [referralForm, setReferralForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [bookmarkedJobs, setBookmarkedJobs] = useState([]);
  const [selectOtherCompany, setSelectOtherCompany] = useState(false);

  const { bookmarkedJobsData } = useSelector((state) => state.fetch_user_job_bookmarks_reducer);

  const dispatch = useDispatch();

  useEffect(() => {
    const callApi = async () => {
      setLoading(true);
      await dispatch(fetch_user_bookmark_jobs_action(jobApplyStatus.bookmarked));
      setLoading(false);
    };

    callApi();
  }, []);

  useEffect(() => {
    if (bookmarkedJobsData?.data) {
      if (bookmarkedJobsData?.status === jobApplyStatus.bookmarked) {
        setBookmarkedJobs(bookmarkedJobsData.data.jobBookmarks);
      }
    }
  }, [bookmarkedJobsData]);

  const handleReferralFormChange = (e) => {
    const { name, value } = e.target;
    setReferralForm({
      ...referralForm,
      [name]: value,
    });
  };

  const onSaveReferralRequestClick = async () => {
    setLoading(true);
    if (selectedReferral?._id) {
      await dispatch(update_referral_request_action(selectedReferral?._id, referralForm));
    }
    else await dispatch(create_referral_request_action(referralForm));
    setLoading(false);
    setReferralForm({});
    toggleReferralRequestModal(null);
  };

  const handleCompanyNotListed = (e) => {
    setSelectOtherCompany(e);
  };

  const onCompanyChange = (e) => {
    setReferralForm({
      target: { name: 'company_id', value: e?._id },
    });
    setSelectOtherCompany(false);
  };

  return (
    <Modal
      open={requestReferralModalOpen}
      onCancel={toggleReferralRequestModal}
      footer={[
        <Button key="save" onClick={() => onSaveReferralRequestClick()}>
          Save Referral
        </Button>,
      ]}
      centered
      title="Add Referral"
    >
      {loading ? (
        <div className="centered spinner-box">
          <Spin size="large" />
        </div>
      ) : (
        <div className="space-y-3">
          <div className="flex flex-col">
            <label className="mb-1 font-medium text-gray-700">
              <span className="text-red-500">*</span> Select Job
            </label>
            <select
              onChange={(e) =>
                handleReferralFormChange({
                  target: { name: 'job_id', value: e.target.value },
                })
              }
              name="job_id"
              className="p-2 border rounded bg-transparent"
              disabled={selectedReferral?._id}
            >
              {!selectedReferral?._id && (
                <option disabled={true} value="" selected={referralForm?.job_id === undefined}>
                  Select Job
                </option>
              )}
              {Array.isArray(bookmarkedJobs) &&
                bookmarkedJobs.map((item) => (
                  <option
                    key={item._id}
                    value={item._id}
                    selected={selectedReferral?.job_id?._id === item._id || referralForm?.job_id === item._id}
                  >
                    {item?.title} at {item?.company}
                  </option>
                ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label className="mb-1 font-medium text-gray-700">
              <span className="text-red-500">*</span> Select Company
            </label>

            {!selectOtherCompany && (
              <CompanySearchBasic
                defaultValue={selectedReferral?.company_id?._id || referralForm.company_id}
                onSearchChange={(e) =>
                  handleReferralFormChange({
                    target: { name: 'company_id', value: e },
                  })
                }
              />
            )}

            <div className="flex items-center mt-2">
              <input
                type="checkbox"
                id="active"
                name="active"
                checked={selectOtherCompany}
                onChange={(e) => handleCompanyNotListed(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="active" className="font-medium text-gray-700">
                Company Not Listed here
              </label>
            </div>

            {selectOtherCompany && <CreateCompany onCompanyChange={onCompanyChange} />}
          </div>

          <div className="flex flex-col">
            <label className="mb-1 font-medium text-gray-700">
              <span className="text-red-500">*</span> Referral Country
            </label>
            <CountrySearchBasic
              defaultValue={selectedReferral?.country_id?._id || referralForm?.country_id}
              onSearchChange={(e) =>
                handleReferralFormChange({
                  target: { name: 'country_id', value: e },
                })
              }
            />
          </div>

          <div className="flex flex-col">
            <label className="mb-1 font-medium text-gray-700">
              <span className="text-red-500">*</span> Seniority Level
            </label>
            <SeniorityLevelBasic
              selectedValue={selectedReferral?.seniority_level || referralForm?.seniority_level}
              onSeniorityLevelChange={(e) =>
                handleReferralFormChange({
                  target: { name: 'seniority_level', value: e },
                })
              }
            />
          </div>
          <InputField
            id="request_message"
            name="request_message"
            label="Additional Information"
            inputType="textarea"
            placeholder="Enter any additional information"
            value={selectedReferral?.request_message || referralForm.request_message}
            onChange={handleReferralFormChange}
            //   error={errors.request_message}
            required
            containerClassName="flex flex-col"
            inputClassName="p-2 border rounded bg-transparent"
            errorClassName="text-xs"
          />
        </div>
      )}
    </Modal>
  );
};

export default AddReferralRequestModal;
