import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetch_all_requested_referrals_action, fetch_user_referral_requests_action } from '../../../_redux/action';
import { navigateRoutes } from '../../../_config/route';

function useDashboard(props) {
  const [loading, setLoading] = useState(false);
  const [requestedReferrals, setRequestedReferrals] = useState([]);
  const [referralRequests, setReferralRequests] = useState([]);
  const [requestReferralModalOpen, setRequestReferralModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { requestedReferralsData } = useSelector((state) => state.fetch_all_requested_referrals_reducer);

  const { referralRequestsData } = useSelector((state) => state.fetch_user_referral_requests_reducer);

  useEffect(() => {
    if (requestedReferralsData) {
      setRequestedReferrals(requestedReferralsData);
    }
  }, [requestedReferralsData]);

  useEffect(() => {
    if (referralRequestsData) {
      setReferralRequests(referralRequestsData);
    }
  }, [referralRequestsData]);

  useEffect(() => {
    const callRequestedReferralsApi = async () => {
      setLoading(true);
      await dispatch(fetch_all_requested_referrals_action());
      await dispatch(fetch_user_referral_requests_action());
      setLoading(false);
    };
    callRequestedReferralsApi();
  }, []);

  const onReferralPreferencesClick = () => {
    navigate(navigateRoutes.referralPreferences);
  };


  const onViewAllReferralRequestsClick = () => {
    navigate(navigateRoutes.referralRequests);
  };

  const onViewAllRequestedReferralClick = () => {
    navigate(navigateRoutes.requestedReferrals);
  };

  const toggleReferralRequestModal = async() => {
    setRequestReferralModalOpen((v) => !v);
    await dispatch(fetch_user_referral_requests_action());
  };

  const onReferralDetailsClick = (referral) => { 
    if(referral?._id){
      navigate(navigateRoutes.referralDetails.replace(':id', referral?._id));
    }
  }

  return {
    loading,
    requestedReferrals,
    referralRequests,
    requestReferralModalOpen,

    onReferralPreferencesClick,
    onViewAllReferralRequestsClick,
    onViewAllRequestedReferralClick,
    toggleReferralRequestModal,
    onReferralDetailsClick
  };
}

export default useDashboard;
