export const masterConstants = {
  FETCH_SKILLS_ACTION_REQUEST: 'FETCH_SKILLS_ACTION_REQUEST',
  FETCH_SKILLS_ACTION_SUCCESS: 'FETCH_SKILLS_ACTION_SUCCESS',
  FETCH_SKILLS_ACTION_FAILURE: 'FETCH_SKILLS_ACTION_FAILURE',

  FETCH_HEADLINES_ACTION_REQUEST: 'FETCH_HEADLINES_ACTION_REQUEST',
  FETCH_HEADLINES_ACTION_SUCCESS: 'FETCH_HEADLINES_ACTION_SUCCESS',
  FETCH_HEADLINES_ACTION_FAILURE: 'FETCH_HEADLINES_ACTION_FAILURE',

  FETCH_PRONOUNS_ACTION_REQUEST: 'FETCH_PRONOUNS_ACTION_REQUEST',
  FETCH_PRONOUNS_ACTION_SUCCESS: 'FETCH_PRONOUNS_ACTION_SUCCESS',
  FETCH_PRONOUNS_ACTION_FAILURE: 'FETCH_PRONOUNS_ACTION_FAILURE',

  FETCH_COLLEGES_ACTION_REQUEST: 'FETCH_COLLEGES_ACTION_REQUEST',
  FETCH_COLLEGES_ACTION_SUCCESS: 'FETCH_COLLEGES_ACTION_SUCCESS',
  FETCH_COLLEGES_ACTION_FAILURE: 'FETCH_COLLEGES_ACTION_FAILURE',

  FETCH_CITIES_ACTION_REQUEST: 'FETCH_CITIES_ACTION_REQUEST',
  FETCH_CITIES_ACTION_SUCCESS: 'FETCH_CITIES_ACTION_SUCCESS',
  FETCH_CITIES_ACTION_FAILURE: 'FETCH_CITIES_ACTION_FAILURE',

  FETCH_REGIONS_ACTION_REQUEST: 'FETCH_REGIONS_ACTION_REQUEST',
  FETCH_REGIONS_ACTION_SUCCESS: 'FETCH_REGIONS_ACTION_SUCCESS',
  FETCH_REGIONS_ACTION_FAILURE: 'FETCH_REGIONS_ACTION_FAILURE',

  FETCH_COUNTRIES_ACTION_REQUEST: 'FETCH_COUNTRIES_ACTION_REQUEST',
  FETCH_COUNTRIES_ACTION_SUCCESS: 'FETCH_COUNTRIES_ACTION_SUCCESS',
  FETCH_COUNTRIES_ACTION_FAILURE: 'FETCH_COUNTRIES_ACTION_FAILURE',

  FETCH_COMPANIES_ACTION_REQUEST: 'FETCH_COMPANIES_ACTION_REQUEST',
  FETCH_COMPANIES_ACTION_SUCCESS: 'FETCH_COMPANIES_ACTION_SUCCESS',
  FETCH_COMPANIES_ACTION_FAILURE: 'FETCH_COMPANIES_ACTION_FAILURE',

  FETCH_COURSES_ACTION_REQUEST: 'FETCH_COURSES_ACTION_REQUEST',
  FETCH_COURSES_ACTION_SUCCESS: 'FETCH_COURSES_ACTION_SUCCESS',
  FETCH_COURSES_ACTION_FAILURE: 'FETCH_COURSES_ACTION_FAILURE',

  FETCH_COURSE_TYPES_ACTION_REQUEST: 'FETCH_COURSE_TYPES_ACTION_REQUEST',
  FETCH_COURSE_TYPES_ACTION_SUCCESS: 'FETCH_COURSE_TYPES_ACTION_SUCCESS',
  FETCH_COURSE_TYPES_ACTION_FAILURE: 'FETCH_COURSE_TYPES_ACTION_FAILURE',

  CREATE_COMPANY_ACTION_REQUEST: 'CREATE_COMPANY_ACTION_REQUEST',
  CREATE_COMPANY_ACTION_SUCCESS: 'CREATE_COMPANY_ACTION_SUCCESS',
  CREATE_COMPANY_ACTION_FAILURE: 'CREATE_COMPANY_ACTION_FAILURE',
};
