import React, { useState } from 'react';
import InputField from './InputField';
import { Button, Modal, Spin } from 'antd';
import { create_company_action, fetch_companies_action } from '../_redux/action';
import { useDispatch } from 'react-redux';

const CreateCompany = ({ onCompanyChange }) => {
  const [companyForm, setCompanyForm] = useState();
  const [companyLoading, setCompanyLoading] = useState(false);

  const dispatch = useDispatch();

  const handleCompanyFormChange = (e) => {
    const { name, value } = e.target;
    setCompanyForm({
      ...companyForm,
      [name]: value,
    });
  };

  async function onCompanySaveClick() {
    setCompanyLoading(true);
    const companyData = await dispatch(create_company_action(companyForm));

    if (companyData?.data) {
      onCompanyChange(companyData?.data);
      await dispatch(fetch_companies_action());
    }
    setCompanyLoading(false);
  }

  return (
    <div>
      {companyLoading ? (
        <div className="centered spinner-box">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <InputField
            id="company_name"
            name="company_name"
            // label="Company Name"
            placeholder="Enter company name"
            value={companyForm?.company_name || ''}
            onChange={handleCompanyFormChange}
            //   error={errors.request_message}
            required
            containerClassName="flex flex-col"
            inputClassName="p-2 border rounded bg-transparent"
            errorClassName="text-xs"
          />
          <InputField
            id="website_url"
            name="website_url"
            // label="Company Website"
            placeholder="Enter company website"
            value={companyForm?.website_url || ''}
            onChange={handleCompanyFormChange}
            //   error={errors.request_message}
            required
            containerClassName="flex flex-col"
            inputClassName="p-2 border rounded bg-transparent mt-2"
            errorClassName="text-xs"
          />
          <Button className="mt-2" onClick={() => onCompanySaveClick()}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default CreateCompany;
