import React from 'react';
import MainLayout from '../../../_layout/MainLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faClock, faLayerGroup, faLocationDot, faMessage } from '@fortawesome/free-solid-svg-icons';
import { Spin } from 'antd';
import useDashboard from './useDashboard';
import AddReferralRequestModal from '../../../_component/AddReferralRequestModal';

function Dashboard(props) {
  const {
    loading,
    requestedReferrals,
    referralRequests,
    requestReferralModalOpen,

    onReferralPreferencesClick,
    onViewAllReferralRequestsClick,
    onViewAllRequestedReferralClick,
    toggleReferralRequestModal,
    onReferralDetailsClick,
  } = useDashboard(props);

  return (
    <MainLayout activeMenuItem="home">
      <div className="p-4 sticky h-screen">
        <div className="centered justify-between mb-4 window-container ">
          <h2 className="window-title">Dashboard</h2>
          <div className="centered gap-4 justify-center max-[540px]:gap-1">
            <div className="centered">
              <button
                className="btn bg-slate-50 text-gray-900 rounded-xl outline outline-1 hover:bg-slate-200"
                onClick={() => onReferralPreferencesClick()}
              >
                My Referral Perferences
              </button>
            </div>
            <div className="centered">
              <button
                className="btn bg-slate-50 text-gray-900 rounded-xl outline outline-1 hover:bg-slate-200"
                onClick={() => toggleReferralRequestModal()}
              >
                Ask Referral Request
              </button>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="centered h-[80vh] spinner-box">
            <Spin size="large" />
          </div>
        ) : (
          <section className="centered flex-col gap-2 w-full">
            <div className="overflow-auto w-full flex-col  flex gap-4">
              <div className="grid grid-cols-[1fr_0.8fr] gap-4">
                <div className="h-full  border border-[#e9e7e4] shadow-[5px_#0003] rounded-2xl bg-[#faf9f8] p-3">
                  <div className="flex flex-row justify-between items-center  mb-4">
                    <h3 className="text-xl font-medium">Requested Referrals</h3>
                    <button
                      className="hover:underline font-medium text-gray-800 hover:text-blue-800"
                      onClick={() => onViewAllRequestedReferralClick()}
                    >
                      View All
                    </button>
                  </div>
                  <div className="flex flex-col gap-4  h-full overflow-y-auto lg:min-h-[200px] max-h-[400px]">
                    {(requestedReferrals || []).length > 0 ? (
                      (requestedReferrals || []).map((referral) => (
                        <div className="p-4 grid grid-cols-[auto_1fr] gap-3 rounded-2xl border items-center bg-white">
                          <div className="flex flex-col">
                            <div className="Job-type-tags flex flex-row gap-2">
                              <span className="p-1.5 rounded-lg bg-[#FFE0A9] text-sm">
                                {referral?.company_id?.company_name}
                              </span>
                              {/* <span className="p-1.5 rounded-lg bg-[#D4E4F9] text-sm">Engineering</span> */}
                            </div>
                            <h3 className="text-2xl font-medium mt-1">{referral?.job_id?.title}</h3>
                            <div className="flex flex-row gap-4 items-center mt-1">
                              <p className="text-xs">
                                <FontAwesomeIcon icon={faLocationDot} className="mr-0.5" />
                                {referral?.country_id?.display_name}
                              </p>
                              <p className="text-xs">
                                <FontAwesomeIcon icon={faClock} className="mr-0.5" />{' '}
                                {referral?.seniority_level.toUpperCase()}
                              </p>
                            </div>
                            <p className="text-xs mt-3">
                              <FontAwesomeIcon icon={faMessage} className="mr-0.3" /> {referral?.request_message}
                            </p>
                          </div>
                          <div className="flex flex-col items-end justify-between gap-4">
                            <p className="bg-[#e5ecec] px-3 text-[#003742] rounded-3xl text-sm">Requested</p>
                            <button
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn bg-white border-2 rounded-3xl px-5 py-1.5"
                              onClick={() => onReferralDetailsClick(referral)}
                            >
                              View
                            </button>
                            <span className="text-xs text-gray-500">
                              {referral?.createdAt &&
                                new Date(referral.createdAt).toLocaleDateString('en-US', {
                                  day: '2-digit',
                                  month: 'long',
                                  year: 'numeric',
                                })}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No Requested Referrals matching your preferences.</p>
                    )}
                  </div>
                </div>
                <div className="h-full  border shadow-[5px_#003] rounded-2xl bg-[#f7fcec] p-3">
                  <div className="flex flex-row justify-between items-center  mb-4">
                    <h3 className="text-xl font-medium">My Referral Requests </h3>
                    <button
                      className="hover:underline font-medium text-gray-800 hover:text-blue-800"
                      onClick={() => onViewAllReferralRequestsClick()}
                    >
                      View All
                    </button>
                  </div>
                  <div className="flex flex-col w-full gap-4 lg:min-h-[200px] max-h-[400px] overflow-auto">
                    {referralRequests && referralRequests.length > 0 ? (
                      referralRequests.map((referral) => (
                        <div
                          className="p-4 grid grid-cols-[auto_1fr] gap-3 rounded-2xl border items-center bg-white"
                          key={referral?._id}
                        >
                          <div className="flex flex-col">
                            <div className="Job-type-tags flex flex-row gap-2">
                              <span className="p-1.5 rounded-lg bg-[#FFE0A9] text-xs">
                                {referral?.company_id?.company_name}
                              </span>
                            </div>
                            <h3 className="text-2xl font-medium mt-1">
                              {referral?.job_id?.title?.trim().replace(/^\w/, (c) => c.toUpperCase()) || 'No Job Title'}
                            </h3>
                            <div className="flex flex-row gap-4 items-center mt-1">
                              <p className="text-xs">
                                <FontAwesomeIcon icon={faLocationDot} className="mr-0.5" />{' '}
                                {referral?.country_id?.display_name || 'N/A'}
                              </p>
                              <p className="text-xs">
                                <FontAwesomeIcon icon={faLayerGroup} className="mr-0.5" />{' '}
                                {referral?.seniority_level?.replace(/^\w/, (c) => c.toUpperCase()) || 'N/A'}
                              </p>
                            </div>
                            <p className="text-xs mt-2">
                              <FontAwesomeIcon icon={faMessage} className="mr-0.3" /> {referral?.request_message}
                            </p>
                          </div>
                          <div className="flex flex-col items-end justify-between gap-2">
                            <p className="bg-[#e5ecec] px-3 text-[#003742] rounded-3xl text-sm">
                              {referral?.status?.replace(/^\w/, (c) => c.toUpperCase())}
                            </p>
                            {/* View Button */}
                            <button
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn bg-white border-2 rounded-3xl px-5 py-1.5"
                              onClick={() => onReferralDetailsClick(referral)}
                            >
                              View
                            </button>
                            <span className="text-xs text-gray-500">
                              {referral?.createdAt
                                ? new Date(referral.createdAt).toLocaleDateString('en-US', {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                  })
                                : 'No date available'}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No Referrals Found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        <AddReferralRequestModal
          toggleReferralRequestModal={toggleReferralRequestModal}
          requestReferralModalOpen={requestReferralModalOpen}
        />
      </div>
    </MainLayout>
  );
}

export default Dashboard;
