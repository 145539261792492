import { showSuccessMessage, showWarningMessage } from '../../../_component/MessageSnack';
import axiosInstance from '../../../_config/AxiosInstance';
import config from '../../../_config/config';
import { profileConstants } from '../../constant';

export const fetch_candidate_profile_action = () => async (dispatch) => {
  dispatch({
    type: profileConstants.FETCH_CANDIDATE_PROFILE_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.candidate.profile);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: profileConstants.FETCH_CANDIDATE_PROFILE_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: profileConstants.FETCH_CANDIDATE_PROFILE_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const update_candidate_profile_action = (data) => async (dispatch) => {
  dispatch({
    type: profileConstants.UPDATE_CANDIDATE_PROFILE_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(config.candidate.profile, data);
    if (response.status) {
      let data = response.data;
      showSuccessMessage(data.message);
      dispatch({
        type: profileConstants.UPDATE_CANDIDATE_PROFILE_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: profileConstants.UPDATE_CANDIDATE_PROFILE_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};


export const fetch_candidate_public_profile_action = (id) => async (dispatch) => {
  dispatch({
    type: profileConstants.FETCH_CANDIDATE_PUBLIC_PROFILE_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.candidate.publicProfile.replace(':id', id));
    if (response.status) {
      let data = response.data;
      dispatch({
        type: profileConstants.FETCH_CANDIDATE_PUBLIC_PROFILE_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: profileConstants.FETCH_CANDIDATE_PUBLIC_PROFILE_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};