export const profileConstants = {
  FETCH_CANDIDATE_PROFILE_ACTION_REQUEST: 'FETCH_CANDIDATE_PROFILE_ACTION_REQUEST',
  FETCH_CANDIDATE_PROFILE_ACTION_SUCCESS: 'FETCH_CANDIDATE_PROFILE_ACTION_SUCCESS',
  FETCH_CANDIDATE_PROFILE_ACTION_FAILURE: 'FETCH_CANDIDATE_PROFILE_ACTION_FAILURE',

  UPDATE_CANDIDATE_PROFILE_ACTION_REQUEST: 'UPDATE_CANDIDATE_PROFILE_ACTION_REQUEST',
  UPDATE_CANDIDATE_PROFILE_ACTION_SUCCESS: 'UPDATE_CANDIDATE_PROFILE_ACTION_SUCCESS',
  UPDATE_CANDIDATE_PROFILE_ACTION_FAILURE: 'UPDATE_CANDIDATE_PROFILE_ACTION_FAILURE',

  FETCH_CANDIDATE_PUBLIC_PROFILE_ACTION_REQUEST: 'FETCH_CANDIDATE_PUBLIC_PROFILE_ACTION_REQUEST',
  FETCH_CANDIDATE_PUBLIC_PROFILE_ACTION_SUCCESS: 'FETCH_CANDIDATE_PUBLIC_PROFILE_ACTION_SUCCESS',
  FETCH_CANDIDATE_PUBLIC_PROFILE_ACTION_FAILURE: 'FETCH_CANDIDATE_PUBLIC_PROFILE_ACTION_FAILURE',
};
