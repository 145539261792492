import React from 'react';
import MainLayout from '../../../_layout/MainLayout';
import useRequestedReferrals from './useRequestedReferrals';
import { Spin, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faLocationDot } from '@fortawesome/free-solid-svg-icons';

const RequestedReferrals = () => {
  const {
    loading,
    requestedReferrals,
    acceptedReferrals,
    fulfilledReferrals,

    onReferralPreferencesClick,
    onReferralDetailsClick,
  } = useRequestedReferrals();

  return (
    <MainLayout activeMenuItem="home">
      <div className="p-4 sticky h-screen">
        <div className="centered justify-between mb-4 window-container">
          <h2 className="window-title">All Requested Referrals</h2>
          <div className="centered gap-4 justify-center max-[540px]:gap-1">
            <div className="centered">
              <button
                className="btn bg-slate-50 text-gray-900 rounded-xl outline outline-1 hover:bg-slate-200"
                onClick={() => onReferralPreferencesClick()}
              >
                Update Referral Perferences
              </button>
            </div>
            {/* <div className="centered">
              <button
                className="btn bg-slate-50 text-gray-900 rounded-xl outline outline-1 hover:bg-slate-200"
                onClick={() => toggleReferralRequestModal()}
              >
                Ask Referral Request
              </button>
            </div> */}
          </div>
        </div>

        {loading ? (
          <div className="centered h-[80vh] spinner-box">
            <Spin size="large" />
          </div>
        ) : (
          <div className="flex flex-col gap-4 pb-4">
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="New" key="newReferrals" className="py-2 px-4 border-b-2 font-medium">
                <div className="border border-[#e9e7e4] shadow-[5px_#0003] rounded-2xl bg-[#faf9f8] p-4 flex flex-col gap-4">
                  {(requestedReferrals || []).length > 0 ? (
                    (requestedReferrals || []).map((referral) => (
                      <div className=" flex items-center  flex-row justify-between bg-white p-3 rounded-xl border">
                        <div className=" flex flex-col items-start">
                          <h3 className="text-lg font-medium tracking-tight">{referral?.job_id?.title}</h3>
                          <div className="space-x-3 text-start text-sm">
                            <span>
                              <FontAwesomeIcon icon={faBuilding} className="mr-1 text-xs" />
                              {referral?.company_id?.company_name}
                            </span>
                            <span>
                              <FontAwesomeIcon icon={faLocationDot} className="mr-1 text-xs" />
                              {referral?.country_id?.display_name}
                            </span>
                            <span className="bg-emerald-100 text-[#003742] px-2 rounded-2xl">
                              {referral?.seniority_level}
                            </span>
                          </div>
                          <div className="mt-2 text-sm">
                            Asked by:{' '}
                            <p href="#" className="font-medium  hover:underline">
                              {referral?.user_id?.first_name}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col items-end justify-between gap-4">
                          <p className="bg-[#e5ecec] px-3 text-[#003742] rounded-3xl text-sm">
                            {referral?.status && referral.status.toUpperCase()}
                          </p>
                          <button
                            onClick={() => onReferralDetailsClick(referral)}
                            className="btn bg-white border-2 rounded-3xl px-5 py-1.5"
                          >
                            View
                          </button>{' '}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No Requested Referrals matching your preferences.</p>
                  )}
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Accepted" key="acceptedReferrals" className="py-2 px-4 border-b-2 font-medium">
                <div className="border border-[#e9e7e4] shadow-[5px_#0003] rounded-2xl bg-[#faf9f8] p-4 flex flex-col gap-4">
                  {(acceptedReferrals || []).length > 0 ? (
                    (acceptedReferrals || []).map((referral) => (
                      <div className=" flex items-center  flex-row justify-between bg-white p-3 rounded-xl border">
                        <div className=" flex flex-col items-start">
                          <h3 className="text-lg font-medium tracking-tight">
                            {referral?.referral_request_id?.job_id?.title}
                          </h3>
                          <div className="space-x-3 text-start text-sm">
                            <span>
                              <FontAwesomeIcon icon={faBuilding} className="mr-1 text-xs" />
                              {referral?.referral_request_id?.company_id?.company_name}
                            </span>
                            <span>
                              <FontAwesomeIcon icon={faLocationDot} className="mr-1 text-xs" />
                              {referral?.referral_request_id?.country_id?.display_name}
                            </span>
                            <span className="bg-emerald-100 text-[#003742] px-2 rounded-2xl">
                              {referral?.referral_request_id?.seniority_level}
                            </span>
                          </div>
                          <div className="mt-2 text-sm">
                            Asked by:{' '}
                            <p href="#" className="font-medium  hover:underline">
                              {referral?.referral_request_id?.user_id?.first_name}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col items-end justify-between gap-4">
                          <p className="bg-[#e5ecec] px-3 text-[#003742] rounded-3xl text-sm">
                            {referral?.status && referral.status.toUpperCase()}
                          </p>
                          <button
                            onClick={() => onReferralDetailsClick(referral?.referral_request_id)}
                            className="btn bg-white border-2 rounded-3xl px-5 py-1.5"
                          >
                            View
                          </button>{' '}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No accepted referrals by you.</p>
                  )}
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Fulfilled" key="fulfilledReferrals" className="py-2 px-4 border-b-2 font-medium">
                <div className="border border-[#e9e7e4] shadow-[5px_#0003] rounded-2xl bg-[#faf9f8] p-4 flex flex-col gap-4">
                  {(fulfilledReferrals || []).length > 0 ? (
                    (fulfilledReferrals || []).map((referral) => (
                      <div className=" flex items-center  flex-row justify-between bg-white p-3 rounded-xl border">
                        <div className=" flex flex-col items-start">
                          <h3 className="text-lg font-medium tracking-tight">
                            {referral?.referral_request_id?.job_id?.title}
                          </h3>
                          <div className="space-x-3 text-start text-sm">
                            <span>
                              <FontAwesomeIcon icon={faBuilding} className="mr-1 text-xs" />
                              {referral?.referral_request_id?.company_id?.company_name}
                            </span>
                            <span>
                              <FontAwesomeIcon icon={faLocationDot} className="mr-1 text-xs" />
                              {referral?.referral_request_id?.country_id?.display_name}
                            </span>
                            <span className="bg-emerald-100 text-[#003742] px-2 rounded-2xl">
                              {referral?.referral_request_id?.seniority_level}
                            </span>
                          </div>
                          <div className="mt-2 text-sm">
                            Asked by:{' '}
                            <p href="#" className="font-medium  hover:underline">
                              {referral?.referral_request_id?.user_id?.first_name}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col items-end justify-between gap-4">
                          <p className="bg-[#e5ecec] px-3 text-[#003742] rounded-3xl text-sm">
                            {referral?.status && referral.status.toUpperCase()}
                          </p>
                          <button
                            onClick={() => onReferralDetailsClick(referral?.referral_request_id)}
                            className="btn bg-white border-2 rounded-3xl px-5 py-1.5"
                          >
                            View
                          </button>{' '}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No accepted referrals by you.</p>
                  )}
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default RequestedReferrals;
