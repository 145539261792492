import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Spin } from 'antd';
import DatePicker from 'react-datepicker';
import { accept_requested_referral_action } from '../../../../_redux/action';
import InputField from '../../../../_component/InputField';

const AcceptReferralModal = ({ referralDetails, acceptReferralModalOpen, toggleAcceptReferralModalOpen }) => {
  const [acceptReferralForm, setAcceptReferralForm] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setAcceptReferralForm({
      ...acceptReferralForm,
      [name]: value,
    });
  };

  const onAcceptReferralRequestClick = async () => {
    setLoading(true);
    await dispatch(
      accept_requested_referral_action({ ...acceptReferralForm, referral_request_id: referralDetails?._id })
    );
    setLoading(false);
    setAcceptReferralForm({});
    toggleAcceptReferralModalOpen();
  };

  return (
    <Modal
      open={acceptReferralModalOpen}
      onCancel={toggleAcceptReferralModalOpen}
      footer={[
        <Button key="save" onClick={() => onAcceptReferralRequestClick()}>
          Accept
        </Button>,
      ]}
      centered
      title="Accept Referral Request"
    >
      {loading ? (
        <div className="centered spinner-box">
          <Spin size="large" />
        </div>
      ) : (
        <div className="space-y-3">
          <div className="flex flex-col">
            <label className="mb-1 font-medium text-gray-700">
              <span className="text-red-500">*</span> Select Due Date
            </label>
            <DatePicker
              selected={acceptReferralForm?.due_date || ''}
              placeholderText="Select"
              onChange={(date) => {
                handleFormChange({
                  target: { value: date, name: 'due_date' },
                });
              }}
              showMonthDropdown
              showYearDropdown
              className="edit-input flex flex-col"
              filterDate={(date) => {
                const today = new Date();
                const fiveDaysFromNow = new Date();
                fiveDaysFromNow.setDate(today.getDate() + 5);
                return date >= today && date <= fiveDaysFromNow;
              }}
            />
          </div>

          <div className="flex flex-col">
            <InputField
              id="comments"
              label="Comments"
              inputType="textarea"
              name="comments"
              placeholder="Optional Comments"
              defaultValue={acceptReferralForm?.comments}
              onChange={handleFormChange}
              containerClassName="form-group  flex flex-col"
              inputClassName="rounded-2xl border border-gray-800 px-3 py-2 text-sm"
              labelClassName="text-gray-700"
              rows={3}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AcceptReferralModal;
