import axiosInstance from '../../../_config/AxiosInstance';
import config from '../../../_config/config';
import { userReferralConstants } from '../../constant';
import { showWarningMessage } from '../../../_component/MessageSnack';

export const fetch_user_referral_requests_action = (status) => async (dispatch) => {
  dispatch({
    type: userReferralConstants.FETCH_USER_REFERRAL_REQUESTS_ACTION_REQUEST,
  });

  try {
    const queryRoute = status ? `?status=${status}` : '';
    const response = await axiosInstance.get(`${config.referral.allReferralRequests}${queryRoute}`);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userReferralConstants.FETCH_USER_REFERRAL_REQUESTS_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userReferralConstants.FETCH_USER_REFERRAL_REQUESTS_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

// post request for create new referral request
export const create_referral_request_action = (form) => async (dispatch) => {
  dispatch({
    type: userReferralConstants.CREATE_REFERRAL_REQUEST_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.referral.referralRequest, form);

    if (response.status) {
      let data = response.data;
      dispatch({
        type: userReferralConstants.CREATE_REFERRAL_REQUEST_ACTION_SUCCESS,
        payload: data.data,
      });
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userReferralConstants.CREATE_REFERRAL_REQUEST_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

// put request for updating a referral request
export const update_referral_request_action = (id, form) => async (dispatch) => {
  dispatch({
    type: userReferralConstants.UPDATE_REFERRAL_REQUEST_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(`${config.referral.referralRequest}/${id}`, form);

    if (response.status) {
      let data = response.data;
      dispatch({
        type: userReferralConstants.UPDATE_REFERRAL_REQUEST_ACTION_SUCCESS,
        payload: data.data,
      });
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userReferralConstants.UPDATE_REFERRAL_REQUEST_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};
