import axiosInstance from '../../../_config/AxiosInstance';
import config from '../../../_config/config';
import { userReferralConstants } from '../../constant';
import { showWarningMessage } from '../../../_component/MessageSnack';

export const fetch_all_requested_referrals_action = () => async (dispatch) => {
  dispatch({
    type: userReferralConstants.FETCH_ALL_REQUESTED_REFERRAL_ACTION_REQUEST,
  });

  try {
    const response = await axiosInstance.get(config.referral.requestedReferrals);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userReferralConstants.FETCH_ALL_REQUESTED_REFERRAL_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userReferralConstants.FETCH_ALL_REQUESTED_REFERRAL_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const fetch_all_accepted_referrals_action = () => async (dispatch) => {
  dispatch({
    type: userReferralConstants.FETCH_ALL_ACCEPTED_REFERRAL_ACTION_REQUEST,
  });

  try {
    const response = await axiosInstance.get(config.referral.acceptedReferrals);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userReferralConstants.FETCH_ALL_ACCEPTED_REFERRAL_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userReferralConstants.FETCH_ALL_ACCEPTED_REFERRAL_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const fetch_all_fulfilled_referrals_action = () => async (dispatch) => {
  dispatch({
    type: userReferralConstants.FETCH_ALL_FULFILLED_REFERRAL_ACTION_REQUEST,
  });

  try {
    const response = await axiosInstance.get(config.referral.fulfilledReferrals);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userReferralConstants.FETCH_ALL_FULFILLED_REFERRAL_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userReferralConstants.FETCH_ALL_FULFILLED_REFERRAL_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};
export const fetch_requested_referral_details_action = (id) => async (dispatch) => {
  dispatch({
    type: userReferralConstants.FETCH_REQUESTED_REFERRAL_DETAILS_ACTION_REQUEST,
  });

  try {
    const response = await axiosInstance.get(config.referral.requestedReferral.replace(':id', id));
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userReferralConstants.FETCH_REQUESTED_REFERRAL_DETAILS_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userReferralConstants.FETCH_REQUESTED_REFERRAL_DETAILS_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const accept_requested_referral_action = (data) => async (dispatch) => {
  dispatch({
    type: userReferralConstants.ACCEPT_REQUESTED_REFERRAL_ACTION_REQUEST,
  });

  try {
    const response = await axiosInstance.post(config.referral.acceptReferralRequest, data);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userReferralConstants.ACCEPT_REQUESTED_REFERRAL_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userReferralConstants.ACCEPT_REQUESTED_REFERRAL_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const fulfill_requested_referral_action = (data) => async (dispatch) => {
  dispatch({
    type: userReferralConstants.FULFILL_REQUESTED_REFERRAL_ACTION_REQUEST,
  });

  try {
    const response = await axiosInstance.post(config.referral.fulfillReferralRequest, data);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userReferralConstants.FULFILL_REQUESTED_REFERRAL_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userReferralConstants.FULFILL_REQUESTED_REFERRAL_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};
