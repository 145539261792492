import React from 'react';

const dropdownOptions = [
  { key: 'Entry Level', value: 'junior' },
  { key: 'Mid Level', value: 'mid' },
  { key: 'Senior Level', value: 'senior' },
  { key: 'Lead Level', value: 'lead' },
  { key: 'Manager Level', value: 'manager' },
];

function SeniorityLevelBasic({ onSeniorityLevelChange, selectedValue }) {
  return (
    <select
      className="p-2 border rounded bg-transparent"
      onChange={(e) => onSeniorityLevelChange(e.target.value)}
      name={'seniority_level'}
    >
      <option disabled={true} value="" selected={selectedValue === undefined}>
        Select
      </option>
      {dropdownOptions.map((item) => (
        <option key={item.key} selected={selectedValue === item.value} value={item.value}>
          {item.key}
        </option>
      ))}
    </select>
  );
}

export default SeniorityLevelBasic;
