import { envDefaults } from './envDefaults';

const dev = {
  apiUrl: envDefaults.apiUrl,

  auth: {
    register: '/rest-auth/register',
    signin: '/rest-auth/login',
    userMeta: '/rest-auth/user-meta',
    sendVerifyEmail: '/rest-auth/send-verification-email',
    verifyEmail: '/rest-auth/verify-email',

    forgotPassword: '/rest-auth/forgot-password',
    resetPassword: '/rest-auth/reset-password',
  },

  candidate: {
    profile: '/user-profile/candidate-profile',
    publicProfile: '/user-profile/candidate-public-profile/:id',
  },

  skill: {
    getSkills: '/user-skills/skills',
    getSkill: '/user-skills/skill',
  },

  resume: {
    getResumes: '/user-resume/resumes',
    getResume: '/user-resume/resume',
  },

  education: {
    getEducations: '/user-education/educations',
    getEducation: '/user-education/education',
  },

  referral: {
    referralRequest: '/referral/request',
    allReferralRequests:'/referral/requests',
    referralPreference:'/referral/preference',
    allReferralPreferences:'/referral/preferences',

    requestedReferrals:"/referral-accept/all",
    requestedReferral:"/referral-accept/details/:id",
    acceptedReferrals:"/referral-accept/accepted",
    fulfilledReferrals:"/referral-accept/fulfilled",
    acceptReferralRequest:"/referral-accept/accept",
    fulfillReferralRequest:"/referral-accept/fulfill",

  },

  ai: {
    fineTuneResume: '/user-resume/fine-tune-resume',
    allFineTuneResume: '/user-resume/all-fine-tune-resume',
    regenerateFineTuneResume: '/user-resume/regenerate-fine-tune-resume',

    aiMockInterview: '/interview/mock-interview',
    allAiMockInterview: '/interview/mock-interviews',
    aiMockInterviewQuestions: '/interview/mock-interview-questions',
    aiMockInterviewQuestionFeedback: '/interview/mock-interview-question-feedback',

    getMuxUploadUrl: '/interview/mock-interview-upload-url',
    getMuxUploadIds: '/interview/mock-interview-upload-ids',
  },

  profession: {
    getProfessions: '/user-experience/experiences',
    getProfession: '/user-experience/experience',
  },

  master: {
    skills: '/tables/all-skills',
    pronouns: '/tables/all-pronouns',
    headlines: '/tables/all-headlines',
    colleges: '/tables/all-colleges',
    cities: '/tables/all-cities/:id',
    regions: '/tables/all-regions/:id',
    countries: '/tables/all-countries',
    companies: '/tables/all-companies',
    courses: '/tables/all-courses',
    courseTypes: '/tables/all-course-types',

    company: '/tables/company',
  },

  bookmarks: {
    getBookmark: '/user-bookmarks/saved',

    jobBookmark: '/user-bookmarks/bookmark-job',
    jobBookmarks: '/user-bookmarks/bookmark-jobs',

    contactBookmark: '/user-bookmarks/bookmark-contact',
    contactBookmarks: '/user-bookmarks/bookmark-contacts',

    companyBookmark: '/user-bookmarks/bookmark-company',
    companyBookmarks: '/user-bookmarks/bookmark-companies',
  },

  autoApply: {
    createAutoApply: '/auto-apply/create',
  },
};

const config = dev;

export default {
  ...config,
};
