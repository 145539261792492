import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { create_referral_preferences_action, update_referral_preferences_action } from '../_redux/action';
import { Button, Modal, Spin } from 'antd';
import CompanySearchBasic from './Company';
import SeniorityLevelBasic from './SeniorityLevel';
import CountrySearchBasic from './Country';
import CreateCompany from './CreateCompany';

const AddReferralPreferenceModal = ({
  referralPreferenceModalOpen,
  selectedPreference,
  toggleReferralPreferenceModalOpen,
}) => {
  const [preferenceForm, setPreferenceForm] = useState({});
  const [selectOtherCompany, setSelectOtherCompany] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handlePreferenceFormChange = (e) => {
    const { name, value } = e.target;
    setPreferenceForm({
      ...preferenceForm,
      [name]: value,
    });
  };

  const onSaveReferralPreferenceClick = async () => {
    setLoading(true);
    if (selectedPreference?._id) {
      await dispatch(update_referral_preferences_action(selectedPreference._id, preferenceForm));
    } else await dispatch(create_referral_preferences_action(preferenceForm));
    setLoading(false);
    setPreferenceForm({});
    toggleReferralPreferenceModalOpen(null);
  };

  const handleCompanyNotListed = (e) => {
    setSelectOtherCompany(e);
  };

  const onCompanyChange = (e) => {
    setPreferenceForm({
      target: { name: 'company_id', value: e?._id },
    });
    setSelectOtherCompany(false);
  };

  return (
    <Modal
      open={referralPreferenceModalOpen}
      onCancel={toggleReferralPreferenceModalOpen}
      footer={[
        <Button key="save" onClick={() => onSaveReferralPreferenceClick()}>
          Save Preference
        </Button>,
      ]}
      centered
      title="Add Referral"
    >
      {loading ? (
        <div className="centered spinner-box">
          <Spin size="large" />
        </div>
      ) : (
        <div className="space-y-3">
          <div className="flex flex-col">
            <label className="mb-1 font-medium text-gray-700">
              <span className="text-red-500">*</span> Select Company
            </label>

            {!selectOtherCompany && (
              <CompanySearchBasic
                defaultValue={selectedPreference?.company_id?._id || preferenceForm?.company_id}
                onSearchChange={(e) =>
                  handlePreferenceFormChange({
                    target: { name: 'company_id', value: e },
                  })
                }
              />
            )}

            <div className="flex items-center mt-2">
              <input
                type="checkbox"
                id="active"
                name="active"
                checked={selectOtherCompany}
                onChange={(e) => handleCompanyNotListed(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="active" className="font-medium text-gray-700">
                Company Not Listed here
              </label>
            </div>

            {selectOtherCompany && <CreateCompany onCompanyChange={onCompanyChange} />}
          </div>

          <div className="flex flex-col">
            <label className="mb-1 font-medium text-gray-700">
              <span className="text-red-500">*</span> Referral Country
            </label>
            <CountrySearchBasic
              defaultValue={selectedPreference?.country_id?._id || preferenceForm?.country_id}
              onSearchChange={(e) =>
                handlePreferenceFormChange({
                  target: { name: 'country_id', value: e },
                })
              }
            />
          </div>

          <div className="flex flex-col">
            <label className="mb-1 font-medium text-gray-700">
              <span className="text-red-500">*</span> Seniority Level
            </label>
            <SeniorityLevelBasic
              selectedValue={selectedPreference?.seniority_level || preferenceForm?.seniority_level}
              onSeniorityLevelChange={(e) =>
                handlePreferenceFormChange({
                  target: { name: 'seniority_level', value: e },
                })
              }
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AddReferralPreferenceModal;
